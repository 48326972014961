<template>
	<div class="my-1">
		<b-overlay :show="loading">
			<b-card>
				<b-row>
					<b-col cols="2">
						<b-form-checkbox v-if="auth.isUserEnable('match_report_mng')" v-model="selected" @change="changeSelected">
						{{ persona.cognome }} {{ persona.nome }}
						</b-form-checkbox>
						<div v-else>{{ persona.cognome }} {{ persona.nome }}</div>
					</b-col>
					<b-col cols="8" :ref="'container_' + (persona ? persona.id : '')"> <h4 v-if="report && (report.file_status == 'to_create' || report.file_status == 'creating')">Match report in creazione...sarà disponibile a breve!</h4>
						<canvas v-else-if="report && report.pdf" :id="'pdf-canvas_' + persona.id" :width="canvasWidth"></canvas> </b-col>
						<b-col cols="2" class="text-center"> 
							<b-button-group vertical v-if="auth.isUserEnable('match_report_mng')">
								<b-button variant="outline-secondary" size="sm" @click="createReport">{{ report && report.id && report.file ? 'Rigenera report' : 'Genera report' }}</b-button>
								<b-button variant="outline-secondary" size="sm" @click="getReport">Ricarica report</b-button>
								<b-button v-if="report && report.id && report.file" variant="outline-secondary" size="sm" @click="downloadReport">Download</b-button>
								<b-button v-if="report && report.id && report.file" variant="outline-secondary" size="sm" @click="sendReport" :disabled="report.sending_status == 'to_send'">{{ report.sending_status == 'to_send' ? 'Invio in corso...' : 'Invia Report' }}</b-button>
								<b-button v-if="report && report.id" variant="outline-secondary" size="sm" @click="deleteReport">Elimina Report</b-button>
							</b-button-group> 
							<div class="mt-2">
								<p v-if="report && report.sending_status == 'sent'">Report inviato il {{ report.sent_on | formatDateHour }}</p>
								<p v-if="report && report.sending_status == 'no_report_found'" style="color:red">Nessun report trovato da inviare!</p>
								<p v-if="report && report.sending_status == 'no_email_found'" style="color:red">Nessun indirizzo email trovato!</p>
							</div>
						</b-col>
					</b-row>
				</b-card>
			</b-overlay>
		</div>
	</template>

	<script>

	import moment from 'moment'
	import _ from 'lodash'
	import { mapState } from 'vuex';
	import * as types from '@/store/types';
	var Auth = require('@/auth.js');

	export default {
		props: [ 'persona', 'sessione', 'persone_sel' ],
		data: function() {
			return {
				report: null,
				selected: false,
				loading: false,

				pdfDoc: null,
				totalPagesPdf: 0,
				currentPagePdf: 0,

				canvasWidth: 1000,
			}
		},
		mounted() {
			this.canvasWidth = this.$refs['container_' + this.persona.id].offsetWidth;
		},
		created() {
			this.getReport();
		},
		computed: {
			auth: function() {
				return Auth;
			}
		},
		methods: {
			getReport() {
				this.loading = true;
				var params = null;
				if(this.report && this.report.id) {
					params = {id: this.report.id};
				} else {
					params = {persona_id: this.persona.id, sessione_id: this.sessione.id};
				}
				this.$http.get(this.$store.state.apiEndPoint + '/match/report', {params: params}).then(function(data) {
					this.report = data.data;

					if(this.report.pdf) {
						var pdf_data = atob(this.report.pdf);
						PDFJS.getDocument({ data: pdf_data }).then((pdf_doc) => {
							this.totalPagesPdf = pdf_doc.numPages;
							this.pdfDoc = pdf_doc;
							this.currentPagePdf = 0;
							this.showPage(1);
							this.loading = false;
						});

					} else {
						this.loading = false;
					}
				});
			},
			showPage(page_no) {
				this.currentPagePdf = page_no;
				this.pdfDoc.getPage(page_no).then((page) => {	
					var desiredWidth = document.getElementById('pdf-canvas_' + this.persona.id).width;
					var viewport = page.getViewport(1);
					var scale = desiredWidth / viewport.width;
					var scaledViewport = page.getViewport(scale);
					document.getElementById('pdf-canvas_' + this.persona.id).height = scaledViewport.height;
					var renderContext = {
						canvasContext: document.getElementById('pdf-canvas_' + this.persona.id).getContext('2d'),
						viewport: scaledViewport
					};
					page.render(renderContext).then(() => {
					});
				});
			},
			changeSelected() {
				this.$emit('updateSelect', this.persona.id, this.selected);
			},
			createReport() {
				var params = null;
				if(this.report && this.report.id) {
					params = {id: this.report.id};
				} else {
					params = {persona_id: this.persona.id, sessione_id: this.sessione.id};
				}
				this.$http.post(this.$store.state.apiEndPoint + '/match/report/create', params).then(function(data) {
					this.getReport();
				});
			},
			deleteReport() {
				if(confirm("Confermi di voler eliminare il report ?")) {
					this.$http.get(this.$store.state.apiEndPoint + '/match/report/delete', {params: {id: this.report.id }}).then(function(data) {
						this.report = null;
						this.getReport();
					});
				}
			},
			sendReport() {
				if(confirm("Confermi di voler inviare via email il report ?")) {
					this.$http.get(this.$store.state.apiEndPoint + '/match/report/send', {params: {id: this.report.id }}).then(function(data) {
						this.getReport();
					});	
				}
			},
			downloadReport() {
				window.open(this.$store.state.apiEndPoint + '/match/report/pdf/' + this.report.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
			},
		},
		watch: {
			persone_sel: function() {
				if(this.persone_sel.includes(this.persona.id)) {
					this.selected = true;
				} else {
					this.selected = false;
				}
			},
		},
		filters: {
			formatDateHour: function (date) {
				var m = moment.utc(date).local();
				if(m.isValid()) {
					return m.format('DD/MM/YYYY HH:mm'); 
				}
				return "";
			},
		}
	}

	</script>