<template>
	<div v-if="notes">

		<div class="mb-4" v-if="upside">
			<NoteForm ref="form" :model="model" @post="doPost"></NoteForm>
			<hr />
		</div>

		<div v-if="notes.notes.length > 0">
			<Nota v-for="nota in notes.notes" :nota="nota.nota"></Nota>
		</div>
		<div v-else>
			<em>Non ci sono note</em>
		</div>
		<div class="mt-4" v-if="!upside">
			<hr />
			<NoteForm ref="form" :model="model" @post="doPost"></NoteForm>
		</div>

	</div>
</template>

<script>

import _ from 'lodash'
var Auth = require('../../../auth.js');

export default {
	props: [ 'entity', 'entityId', 'hideForm', 'upside' ],
	components: {
		NoteForm: require('./form.vue').default,
		Nota: require('./nota.vue').default
	},
	created: function() {

	},
	computed: {
		user: function() {
			return Auth.user;
		}
	},
	watch: {
		notes: {
			deep: true,
			handler: function() {
				if(this.notes.notes.length == 0 && !this.hideForm) {
					_.delay(function() {
						this.$refs.form.addNote();
					}.bind(this), 100)
				}
			}
		},
		entity: {
			deep: true,
			handler: function() {
				this.fetch();
			},
			immediate: true
		}
	},
	methods: {
		doPost: function() {
			this.$http.post(this.$store.state.apiEndPoint + '/note/of/' + this.entity + '/' + this.entityId + '/post', { note: this.model }).then(function(data) {
				this.fetch();
				this.$emit('post');
			});
		},
		fetch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/note/of/' + this.entity + '/' + this.entityId).then(function(data) {
				this.notes = data.data;
				this.model = {
					testo: '',
					pubblica: true
				};

			});
		}
	},
	filters: {
		
	},
	data: function() {
		return {
			model: false,
			notes: false,
			ux: {
				show: true,
			}
		}
	}
};

</script>