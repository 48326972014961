<template>
	<div class="text-right">
		<div class="pt-2 mx-2">
			<b-form-select 
				style="position: relative; width: 38%; font-size:0.8rem; z-index: 99999999999" 
				v-model="season_ruoli_sel" 
				:options="season_ruoli_options" 
				v-if="season_ruoli_options && season_ruoli_options.length" size="sm"
			>
			</b-form-select>
		</div>

		<div style="position: relative; width:40%; margin-left: 50%; font-size: 20px; justify-self: end;">
			
			<campo style="width: 100%; margin-top: -6px;" />

			<!--div><div v-for="role in ruoli_test" :class="['role-div', role]">100%<br/>{{ role }}</div></div-->
			<div v-if="ruoli_perc && ruoli_perc[season_ruoli_sel]">
				<div 
					v-for="role in ruoli_perc[season_ruoli_sel]" 
					v-if="_.round(role.percentuale) != 0"
					:class="['role-div', role.sigla]"  
					:style="getColor(role, ruoli_perc[season_ruoli_sel])"
				>
					{{ _.round(role.percentuale) }}%<br/>
					{{ role.sigla }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: [ 'player' ],
	components: {
		campo: 	require('@/components/layout/campi/orizzontale_white.vue').default,
	},
	data: function() {
		return {
			ruoli_perc: null,
			season_ruoli_options: null,
			season_ruoli_sel: null,
		//	ruoli_test: ['PO', 'DC', 'DCD', 'DCS', 'TD', 'TS', 'EBD', 'EBS', 'CM', 'CC', 'CCD', 'CCS', 'ED', 'ES', 'TQC', 'TQD', 'TQS', 'AS', 'AD', 'PC', 'SP', 'CDD', 'CDS', 'CED', 'CES'],
		}
	},
	computed: {
		_: function() {
			return _;
		},
	},
	watch: {
		player: {
			immediate: true,
			handler: function() {
				this.fetchRuoli();
			}
		}
	},
	methods: {
		fetchRuoli: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/statsbomb/roles/percentage/' + this.player.persona.id).then(function(data) {
				this.ruoli_perc = data.data;
				this.season_ruoli_options = _.keys(this.ruoli_perc);
				if(this.season_ruoli_options.length > 0) {
					this.season_ruoli_sel = this.season_ruoli_options[0];
				}
			});
		},
		getColor(role, array_roles) {
			var array_sorted = _.orderBy(array_roles, ['percentuale'], ['desc']);
			var findIndex = _.findIndex(array_sorted, item => item.sigla == role.sigla);
			
			if(findIndex == 0) {
				return 'background-color:#FF0000;color:#FFFFFF';
			} else if(findIndex == 1) {
				return 'background-color:#FF8C00;color:#FFFFFF';
			} else {
				return 'background-color:#FFD700;color:#000000';	
			}
		}
	}
}

</script>
<style>
.role-div {
	line-height: 0.5vw;
	height: 1.5vw;
	width: 1.5vw;
	font-size: 0.5vw;
	background-color: red;
	color: #FFFFFF;
	border-radius: 50%;
	display: inline-block;
	padding-top: 0.3rem;
	font-weight: bold;
	text-align: center;
	position: absolute;
}

.PO { top: 40%; left: 6%; }

.DC { top: 40%; left: 11%; }
.DCD { top: 56%; left: 11%; }
.DCS { top: 24%; left: 11%; }
.TD { top: 71%; left: 11%; }
.TS { top: 9%; left: 11%; }

.EBD { top: 71%; left: 26%; }
.EBS { top: 9%; left: 26%; }
.CM { top: 39%; left: 26%; }
.CDD { top: 56%; left: 26%; }
.CDS { top: 24%; left: 26%; }

.CED { top: 71%; left: 48%; }
.CES { top: 9%; left: 48%; }
.CC { top: 39%; left: 48%; }
.CCD { top: 56%; left: 48%; }
.CCS { top: 24%; left: 48%; }

.ED { top: 71%; left: 65%; }
.ES { top: 9%; left: 65%; }
.TQC { top: 39%; left: 65%; }
.TQD { top: 56%; left: 65%; }
.TQS { top: 24%; left: 65%; }

.SP { top: 40%; left: 70%; }

.PC { top: 40%; left: 80%; }
.AD { top: 56%; left: 80%; }
.AS { top: 24%; left: 80%; }

</style>