<template>
	<b-card>
		<template slot="header">xPhy</template>
		<!--E-Chart :option="chartOptions" style="width: 100%; height: 300px"></E-Chart-->
		<div class="text-center">
			<Progress :transitionDuration="1000" :radius="70" :strokeWidth="20" :value="dataset.index.xPhy" :strokeColor="colorIndex">
				<div class="content" style="font-size:2rem"> {{ dataset.index.xPhy }} </div>
				<template v-slot:footer>
					<span class="lead">{{ dataset.ranking_position }}°</span> <span class="text-muted small">/ {{ dataset.ranking_count }}</span>
				</template>
			</Progress>
		</div>
	</b-card>
</template>

<script>

import Progress from "easy-circular-progress";

export default {
	components: {
		Progress
	},
	computed: {
		colorIndex() {
			var bg = '#CCCCCC';
			if(this.dataset && this.dataset.index && this.dataset.index.xPhy) {
				var value = this.dataset.index.xPhy;
				if(value <= 100 && value >= 90) {
					bg = '#00B386';
				} else if(value < 90 && value >= 80) {
					bg = '#2EC85B';
				} else if(value < 80 && value >= 70) {
					bg = '#7BDE3C'; 
				} else if(value < 70 && value >= 60) {
					bg = '#B8F100';
				} else if(value < 60 && value >= 50) {
					bg = '#E9F700';
				} else if(value < 50 && value >= 40) {
					bg = '#FFF300';
				} else if(value < 40 && value >= 30) {
					bg = '#FFDB00';
				} else if(value < 30 && value >= 20) {
					bg = '#FFB800';  
				} else if(value < 20 && value >= 10) {
					bg = '#FF8900';
				} else if(value < 10) {
					bg = '#FF0059';
				}
			}
			return bg;
		}
	},
	props: [ 'dataset' ],
}

</script>