<template>
	<div>
		<b-row class="d-flex align-items-center pb-2">
			<b-col cols="4" class="px-3">

				<div class="d-flex mb-2">
					<div class="lead mr-1">{{player.persona.nome}}</div>
					<div class="lead font-weight-bold">{{player.persona.cognome}}</div>
				</div>

				<div class="d-flex">
					
					<div style="max-height: 130px; overflow: hidden;">
						<img :src="player.persona.avatar_url" style="max-height: 130px; border: 1px solid rgba(0,0,0,0.2); border-radius: 5px;" />
					</div>
					
					<div class="px-3">
						<div class="d-flex align-items-center mt-1">
							<img style="height: 20px" :src="player.persona.last_carriera_sportiva.squadra.logo_url" class="mr-1" />
							<span style="font-size: 1.1rem">{{player.persona.last_carriera_sportiva.squadra.officialName}}</span>
						</div>
						<div style="font-size: 0.9rem">Data di nascita: <strong>{{ player.persona.nascita_data | dob }}</strong></div>
						<div style="font-size: 0.9rem">Nazionalità: <span class="ml-1"><flag :country="player.persona.nascita_paese.sigla" :size="16"></flag> <strong>{{ player.persona.nascita_paese.paese }}</strong></span></div>
						<div style="font-size: 0.9rem"><span class="mr-2">Altezza: <strong>{{ player.persona.info_fisica.altezza }}</strong></span><span>Piede: <strong>{{ player.persona.giocatore.piede == 0 ? 'Destro' : 'Sinistro' }}</strong></span></div>
					</div>
				</div>

			</b-col>
			<b-col cols="8">
				<b-row align-v="center">
					<b-col v-for="comp in $route.meta.v2.headComponents"><div v-if="comp !== null" :is="comp" :player="player"></div></b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import moment from 'moment'

export default {
	props: [ 'player' ],
	data: function() {
		return {
			contractInfo: null,
		}
	},
	components: {
		TransferMarket: require('@/components/v2/Player/comp-TransferMarket.vue').default,
		ScoutFieldPosition: require('@/components/v2/Player/comp-ScoutFieldPosition.vue').default,
		flag: require('@/components/layout/flag.vue').default,
	},
	watch: {
		'player': {
			immediate: true,
			handler: function() {
				console.log('player');
				if(this.player.persona.wyscout) {
					//this.fetchContractInfo();
				}
			}
		},
	},
	methods: {
		
	},
	filters: {
		dob: function(a) {
			return moment(a).format('DD/MM/YYYY');
		}
	}
}	

</script>