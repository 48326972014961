<template>
	<div>

		<headapp></headapp>

		<b-card class="tab-content">
			<div class="content">
				<b-row class="mb-2">
					<b-col cols="6">
						<b-btn size="sm" variant="primary" title="Aggiungi lista" v-b-toggle="'collapse2'">Aggiungi Lista <i class="fa fa-plus" aria-hidden="true"></i></b-btn>
						<b-collapse id="collapse2" ref="collapse2" class="my-2">
							<b-input-group>
								<b-form-input v-model="new_lista" type="text" placeholder="Inserisci nuova lista"></b-form-input>
								<template #append><b-btn variant="primary" @click="aggiungiLista">Salva</b-btn></template>
							</b-input-group>
						</b-collapse>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-table-simple small striped hover>
							<b-thead>
								<b-tr>
									<b-th>Lista</b-th>
									<b-th class="text-center">Azioni</b-th>
									<!--b-th>hasCategory</b-th-->
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="lista in liste">
									<b-td>
										{{lista.nome}} {{ auth.isUserEnable('shadow_team') && isCurrentShadowTeamList(lista.id) ? ' (Current Shadow Team)' : ''}}
										<b-collapse :id="'collapse-' + lista.id" :ref="'collapse-' + lista.id" class="my-2" v-if="!lista.condivisa">
											<b-form-checkbox-group stacked v-model="lista_utenti">
												<b-form-checkbox v-for="item in osservatori_liste" :value="item.id" v-if="item.attivo && item.id != auth.user.id">{{ item.nomeCompleto }}</b-form-checkbox>
											</b-form-checkbox-group>
											<b-btn variant="primary" size="sm" @click="condividiLista">Salva</b-btn>
										</b-collapse>
									</b-td>
									<b-td>
										<b-btn size="sm" variant="primary" title="Schiera in campo" @click.prevent="listaInCampo(lista.id)"><i class="fa fa-futbol-o" aria-hidden="true"></i></b-btn>
										<b-btn size="sm" variant="success" title="Condividi lista" v-b-toggle="'collapse-' + lista.id" v-if="!lista.condivisa"><i class="fa fa-share-alt" aria-hidden="true"></i></b-btn>
										<b-btn size="sm" variant="secondary" title="Impostazioni Lista" @click.prevent="showConfigLista(lista.id)"><i class="fa fa-cog" aria-hidden="true"></i></b-btn>
										<b-btn size="sm" variant="danger" title="Elimina lista" @click="eliminaLista(lista.id)" v-if="!lista.master && !lista.condivisa"><i class="fa fa-trash" aria-hidden="true"></i></b-btn>

									</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-col>	
				</b-row>

			</div>
		</b-card>

		<b-modal ref="modalConfigLista" size="xl">

			<config-lista @refresh="fetchListe" v-if="lista_selected" :listId="lista_selected"></config-lista>

		</b-modal>

		<footerapp></footerapp>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

import moment from 'moment'
import * as types from '../../../store/types'
import { mapState } from 'vuex';

var Auth = require('../../../auth.js');

export default {
	mixins: [ pageBase ],

	components: {
		configLista: require('@/components/layout/scout/config-lista.vue').default,
	},

	data: function(){
		return {
			ux: {
				showConfigLista: false
			},
			osservatori_liste: [],
			liste: [],
			lista_selected: 0,
			lista_giocatori: [],
			lista_utenti: [],
			new_lista: '',
			ruoli: [],
			elenco_pdf: [],
			total: 0,
			loading: false,
			page: 1,
			is_search: false,
			aree: [],
			squadre: [],
			show_aree: false,
			show_squadre: false,
			squadra: null,	
			opta_range: {},
			opta_fields: [],
			confronta: false,
			lista_confronto: [],
			liste_confronto: [],
			nuova_lista_confronto: '',	
			percent_val: null,
			osservato_sel: null,
			count_val: 0,
			loading_val: false,
		}
	},
	created: function () {
		this.loading = true;

		this.$http.get(this.$store.state.apiEndPoint + '/osservatori/liste/newscout').then((response) => {
			if (response.ok)
			{
				this.osservatori_liste = response.data; 
			}
		}, (response) => {
		}); 

		this.fetchListe();

	},

	methods: {
		fetchListe: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/liste', {params: {no_players: true }}).then((response) => {
				if (response.ok)
				{
					this.liste = response.data; 
					this.lista_selected = this.liste[0].id;
				}
			}, (response) => {
			});
		},
		showConfigLista: function(lista_id) {
			this.lista_selected = lista_id;
			this.$refs.modalConfigLista.show();
		},
		getGiocatoriLista(id) {
			this.lista_giocatori = [];
			if(this.lista_selected) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/get/giocatori/lista/' + this.lista_selected).then((response) => {
					if (response.ok)
					{
						var giocatori = response.data;
						for(var i in giocatori) {
							var item = giocatori[i]; 
							this.lista_giocatori.push(item.persona_id);
						}
					}
				}, (response) => {
				}); 
			}
		},
		addGiocatoreLista(id) {
			if(!this.is_lista_condivisa) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/add/giocatore/lista/' + this.lista_selected + '/' + id, {params: {no_liste: true }}).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Giocatore aggiunto alla lista!', status: 1 });
						this.lista_giocatori.push(id);  
					}
				}, (response) => {
				}); 
			}
		},
		delGiocatoreLista(id) {
			if(!this.is_lista_condivisa) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/delete/giocatore/lista/' + this.lista_selected + '/' + id, {params: {no_liste: true }}).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Giocatore eliminato dalla lista!', status: 1 });
						var index = this.lista_giocatori.indexOf(id);	
						this.lista_giocatori.splice(index, 1);
						if(this.filters.lista > 0) {
							this.filtraArchivio();
						}  
					}
				}, (response) => {
				}); 
			}  
		},
		aggiungiLista() {
			if(this.new_lista == '') {
				alert("Inserisci un nome per la lista!");
				return;
			}
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/add/lista/' + this.new_lista, {params: {no_players: true }}).then((response) => {
				if (response.ok)
				{
					this.$refs.collapse2.toggle();
					this.new_lista = '';
					this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista aggiunta ok', status: 1 });
					this.liste = response.data; 
				}
			}, (response) => {
			}); 
		},
		eliminaLista() {
			if(confirm("Confermi l'eliminazione della lista selezionata?")) {

				this.$http.get(this.$store.state.apiEndPoint + '/scouting/delete/lista/' + this.lista_selected, {params: {no_players: true }}).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista eliminata ok', status: 1 });
						this.liste = response.data; 
						this.lista_selected = this.liste[0].id;
					}
				}, (response) => {
				}); 
			}
		},
		condividiLista() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/condividi/lista/' + this.lista_selected + '/' + this.lista_utenti, {params: {no_players: true }}).then((response) => {
				if (response.ok)
				{
					this.$refs.collapse3.toggle();
					this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista condivisa ok', status: 1 });
					this.liste = response.data; 
					this.lista_selected = this.liste[0].id;
				}
			}, (response) => {

			}); 
		},
		listaInCampo(lista_id) {
			this.lista_selected = lista_id;
			this.$router.push({ name: "scout_lista_in_campo", params: { id: this.lista_selected } });
		},

		formatDate (data) {
			var m = moment(data);
			var format = m.format('DD/MM/YYYY'); 
			return format;
		},

		isCurrentShadowTeamList(id) {	
			var lista = this.liste.find((item) => item.id == id);
			for(var i in lista.formazioni) {
				var form = lista.formazioni[i];
				if(form.shadow_team) {
					return true;
				} 
			}
			return false;
		},
	},

	watch: {
		lista_selected: function () {
			this.getGiocatoriLista();
			this.lista_utenti = [];
			for(var index in this.liste) {
				var lista = this.liste[index];
				if(lista.id == this.lista_selected) {
					for(var i in lista.lista_utenti) {
						var item = lista.lista_utenti[i]; 
						this.lista_utenti.push(item.utente_id);
					}
				}
			}	
		},
	},

	computed: {
		auth: function() {
			return Auth;
		},
		is_lista_master: function() {
			for(var index in this.liste) {
				var lista = this.liste[index];
				if(lista.id == this.lista_selected) {
					if(lista.master) {
						return true;

					}
				}
			}
			return false;
		},
		is_lista_condivisa: function() {
			for(var index in this.liste) {
				var lista = this.liste[index];
				if(lista.id == this.lista_selected) {
					if(lista.condivisa) {
						return true;
					}
				}
			}
			return false;
		},
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YY'); 
			}
			return "";
		}
	},

}
</script>
