<template>
	<div>
		<NoiseFeedInjuries :persona="player.persona" :persona_id="player.persona.id" start="graph"></NoiseFeedInjuries>
	</div>
</template>

<script>

export default {
	props: [ 'player' ],
	components: {
		NoiseFeedInjuries: require('@/components/layout/noise_feed/injuries-by-persona.vue').default
	}
}

</script>