<template>
	<div>

		<div v-if="fase.entity.risultati">
			<label>Nome</label>
			<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>
			<b-row v-if="hasSetupTipo(this.fase, 'hideFormDuration', false) == false">
				<b-col>
					<label>Durata</label>
					<b-form-input :disabled="disabled" v-model="fase.durata" type="time"></b-form-input>
				</b-col>
				<b-col>
					<label>Durata effettiva</label>
					<b-form-input :disabled="disabled" v-model="fase.durata_reale" type="time"></b-form-input>
				</b-col>
			</b-row>
			<div v-if="hasSetupTipo(this.fase, 'hasFeeder', false) == true">
				<b-button :disabled="ux.wait" :loading="ux.wait" class="mt-3" variant="outline-secondary" size="sm" @click.prevent="importaDati">Importa dati da Fornitore</b-button>
			</div>

			<div class="mt-3">
				<b-overlay :show="ux.wait">

					<div v-if="fase.entity.risultati.multirow && ux.multiRowPlayerSelect.length == 0">
						<p>Seleziona un giocatore per visualizzarne i dati multipli.</p>
						<ul style="columns: 4">
							<li v-for="i,k in fase.persone"><a href="javascript;" @click.prevent="ux.multiRowPlayerSelect = [ i.id ]">{{i.cognome}}</a> ({{ fase.entity.risultati.table[i.id].length }})</li>
						</ul>
					</div>
					<div v-else>
						<b-button v-if="fase.entity.risultati.multirow" variant="outline-secondary" size="sm" @click.prevent="ux.multiRowPlayerSelect = []">Indietro</b-button>
						<div :is="matriceComponent" :fase="fase" :risultati="realResults"></div>
					</div>
				</b-overlay>
			</div>

			<div v-if="fase.entity.risultati.multirow">
				<b-input-group prepend="Giocatori" size="sm">
					<b-form-select v-model="ux.newRigaPlayer" size="sm">
						<b-form-select-option v-for="player in sessione.persone" :value="player.id">{{ player.cognome }} {{ player.nome }}</b-form-select-option>
					</b-form-select>
					<b-input-group-append>
						<b-button size="sm" @click.prevent="addRow()" :disabled="ux.newRigaPlayer === null">Aggiungi Riga</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
			<!--div v-if="hasSetupTipo(this.fase, 'hasCsv', false) == true && countDoc > 0"-->
			<div v-if="hasSetupTipo(this.fase, 'hasCsv', false) == true && countDoc != 0">	
				<b-button :disabled="ux.wait" :loading="ux.wait" class="mt-3" variant="outline-secondary" size="sm" @click.prevent="importaDati">Importa dati da Csv</b-button>
			</div>
			
		</div>
		<b-alert show v-else>Per poter inserire i valori della raccolta dati, devi prima salvare la sessione.</b-alert>
		


	</div>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';

import axios from 'axios';
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [ sessionMixin ],
	props: [ 'sessione', 'fase', 'disabled', 'countDoc' ],
	components: {
		MiniMatrice: require('@/components/layout/Sessioni/MiniMatrice.vue').default,
		MiniMatriceInvertita: require('@/components/layout/Sessioni/MiniMatriceInvertita.vue').default,
	},
	data: function(){
		return {
			ux: {
				wait: false,
				newRigaPlayer: null,
				multiRowPlayerSelect: []
			}
		}
	},
	methods: {
		addRow: function() {
			var base = _.keys(this.fase.entity.risultati.intestazioni);
			var newRiga = {}

			_.each(base, function(a) {
				newRiga[a] = null;
			});

			if(!this.fase.entity.risultati.table[this.ux.newRigaPlayer])
				this.$set(this.fase.entity.risultati.table, this.ux.newRigaPlayer, []);
			
			this.fase.entity.risultati.table[this.ux.newRigaPlayer].push(newRiga);
			this.ux.newRigaPlayer = null;

		},
		importaDati: function() {
			this.ux.wait = true;
			this.$http.get(this.$store.state.apiEndPoint + '/sessione/' + this.sessione.id + '/importa-dati?fase_id=' + this.fase.id).then(function(data) {
				this.ux.wait = false;
				this.$store.commit(types.ALERTS_PUSH, { msg: data.data.imported + ' misurazioni importate.', status: 1 });
				this.$emit('refresh');
			});

		},
	},
	computed: {
		auth: function() {
			return Auth;
		},
		realResults: function() {

			if(this.fase.entity.risultati.multirow) {

				var table = {};

				_.each(this.ux.multiRowPlayerSelect, function(a) {
					table[a] = this.fase.entity.risultati.table[a];
				}.bind(this))

				return {
					intestazioni: this.fase.entity.risultati.intestazioni,
					multirow: this.fase.entity.risultati.multirow,
					table
				}
			}

			return this.fase.entity.risultati;
		},
		matriceComponent: function() {
			return this.fase.setup.matrixComponent != undefined ? this.fase.setup.matrixComponent : 'MiniMatrice';
		}
	}
}
</script>