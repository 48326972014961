<template>
	<div>
		<headapp></headapp>
		<b-card class="tab-content">
			<ValutazioniPartita :partitaId="$route.params.id" />		
		</b-card>
		<footerapp></footerapp>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
		}
	},
	components: {
		ValutazioniPartita: require('@/components/layout/scout/valutazioni-partita.vue').default,
	},
	created: function() {
	},
	methods: {
	},
	computed: {
	},
}

</script>