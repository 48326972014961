<template id="menutab">
    <div class="card no-body rounded-0" v-if="items.length">
        <div class="card-header pb-0 pt-2 px-2">
            <b-nav tabs>
                <b-nav-item v-for="item in items" :active="item.active ? true : false" :to="item.to">{{ item.content }}</b-nav-item>
            </b-nav>
        </div>
    </div>
</template>

<script>
var Auth = require('../../auth.js');
export default {

    data: function() {
        return {
            items: [],
        }
    },

    methods: {

     update()
     {
        this.items = [];
        for (var index_route in this.$router.options.routes)
        {
            var route = this.$router.options.routes[index_route];
            if (route.meta && (route.meta.module == this.$route.meta.module) && (route.meta.showInTabs) && (route.meta.parent == ''))
            {
                if(route.meta.permesso && !Auth.isUserEnable(route.meta.permesso, this.categoriaId) && this.moduli_categoria.includes(route.meta.module)) {
                    continue;
                }
                if(route.meta.permesso && !Auth.isUserEnable(route.meta.permesso)) {
                    continue;
                }
                if(route.meta.onlyInProva && !this.inProva) {    
                    continue;
                }
                var active = (route.path == this.$route.path);
                this.items.push({ to: route.path, content: route.meta.label, active: active, routeExactMatch: route.meta.routeExactMatch });
            }
        }
    },
},

created: function () {
    this.update();
},

watch: {
    '$route': function (newValues) {
        this.update();
    },
},
}
</script>
