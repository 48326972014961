<template>
	<div>
		<div class="card-body">
			<b-row>
				<b-col><span class="mr-2 color-rosso"><h5>{{ partita.competizione.paese.paese }} - {{ partita.competizione.nome }}</h5></span></b-col>
				<b-col cols="1"><b-button size="sm" @click.prevent="showValutazioniPartita"><i class="fa fa-search"></i></b-button></b-col>
			</b-row>
			<!--div>
				<span class="mr-2 color-rosso"><h5>{{ partita.competizione.paese.paese }} - {{ partita.competizione.nome }}</h5></span>
			</div-->
			<div class="cardpartita">
				<router-link :to="partitaRoute" style="text-decoration:none" target="_blank">
					<div class="versus">
						<div class="row flex-items-xs-middle flex-items-xs-center text-sm-center">
							<div class="col-5" v-if="teamHome">
								<div><b-img :src="teamHome.logo_url" fluid :alt="teamHome.officialName" :width="80"></b-img></div>
								<div>
									<span>{{ teamHome.officialName }}</span>
								</div>
							</div>
							<div class="col-5" v-else>	
								Home
							</div>
							<div class="col-2">

								<div class="versus__vs">
									<span>VS.</span>
								</div>
								<div class="text-center mt-4">
									<span class="color-rosso pl-1">{{ partita.score }}</span>
								</div>
							</div>
							<div class="col-5" v-if="teamAway">

								<div><b-img :src="teamAway.logo_url" fluid :alt="teamAway.officialName" :width="80"></b-img></div>
								<div>
									<span>{{ teamAway.officialName }}</span>
								</div>
							</div>
							<div class="col-5" v-else>	
								Away
							</div>
						</div>
					</div>
				</router-link>
				<div class="row">
					<div class="col-12 text-sm-center">
						{{ partita.data | formatDateHour }}
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="valutazioniModal" size="full" ok-only ok-title="Chiudi">
			<ValutazioniPartita :partitaId="partita.id" />		
		</b-modal>
	</div>	
</template>

<script>

import moment from 'moment';
export default {

	props: [ 'partita' ],

	components: {
		ValutazioniPartita: require('@/components/layout/scout/valutazioni-partita.vue').default,
	},

	computed: {
		partitaRoute: function() {
			return '/scout/' + this.partita.competizione.paese.continente_id + '/' + this.partita.competizione.id_paese + '/' + this.partita.id_competizione + '/' + this.teamHome.id + '/evento-partite-wyscout/' + this.partita.id + '/' + this.partita.wyscout_id;
		},
		teamHome() {
			if(!this.partita)
				return null; 
			var squadre = this.partita.squadre;
			if(squadre && squadre.length > 1) {
				if(squadre[0].pivot.side == 'home') {
					return squadre[0];
				} else {
					return squadre[1];
				}
			}
			return null;
		},

		teamAway() {
			if(!this.partita)
				return null; 
			var squadre = this.partita.squadre;
			if(squadre && squadre.length > 1) {
				if(squadre[0].pivot.side == 'home') {
					return squadre[1];
				} else {
					return squadre[0];
				}
			}
			return null;
		},
	},

	methods: {
		showValutazioniPartita() {
			this.$refs.valutazioniModal.show();
		}
	},

	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}

</script>