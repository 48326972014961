<template>
	<b-card>
		<template slot="header">Valutazioni giocatore</template>
		<div class="text-center">
			<E-Chart :option="chartOptions" style="width: 100%; height: 180px"></E-Chart>
			<div style="z-index: 999999999; position: relative;" class="text-center">
				<span class="lead">{{ valutazioniTotal }} Valutazioni</span>
			</div>
		</div>
	</b-card>
</template>

<script>

import eChart from '@/components/layout/E-Chart/Base.vue';

export default {
	components: {
		'E-Chart': eChart
	},
	computed: {
		chartOptions: function() {

			return {
				// legend: {
				// 	bottom: '0%',
				// 	left: 'center'
				// },
				color: this.colors,
				tooltip: {
					trigger: 'item'
				},
				series: [{
					name: 'Valutazioni',
					type: 'pie',
					radius: ['62%', '90%'],
					avoidLabelOverlap: true,
					itemStyle: {
						borderRadius: 2,
						borderColor: '#fff',
						borderWidth: 2
					},
					label: {
						show: false,
						position: 'center'
					},
					emphasis: {
						label: {
							show: true,
							fontSize: 14,
						}
					},
					labelLine: {
						show: true
					},
					data: this.dataset
				}]
			}

		}
	},
	mounted: function(){
		this.fetchData();
	},
	methods: {
		fetchData: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/fr-valutazione/aggregato/get/' + this.playerId ).then(
				function({body: data}) {
					this.dataset = [
						{ value: data.qty_val_0_1 ?? 0, name: 'Valutazione 0-1' },
						{ value: data.qty_val_1_2 ?? 0, name: 'Valutazione 1-2' },
						{ value: data.qty_val_2_3 ?? 0, name: 'Valutazione 2-3' },
						{ value: data.qty_val_3_4 ?? 0, name: 'Valutazione 3-4' },
						{ value: data.qty_val_4_5 ?? 0, name: 'Valutazione 4-5' }
					];

					this.valutazioniTotal = (data.qty_val_0_1 ?? 0) + (data.qty_val_1_2 ?? 0) + (data.qty_val_2_3 ?? 0) + (data.qty_val_3_4 ?? 0) + (data.qty_val_4_5 ?? 0);

					if( this.valutazioniTotal === 0 ){
						this.colors = ['#dddddd']
						this.dataset = [{ value: 0, name: 'Nessuna valutazione' }]
					}else{
						this.colors = this.getBasicColors()
					}
				}
			);
		},
		getBasicColors: function() {
			return [
				'#674ea7',
				'#ea4336',
				'#ff6d01',
				'#34a853',
				'#46bdc6'
			]
		}
	},
	props: [ 'playerId' ],
	data: function() {
		return {
			dataset: [],
			valutazioniTotal: 0,
			colors: this.getBasicColors(),
		}
	}
}

</script>