<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<div style="position: fixed; bottom: 0; left: 0; width: 100%; z-index: 9999">
			<b-button size="lg" variant="primary" style="position: absolute; bottom: 30px; left: 20px;border-radius:100%" v-b-toggle.sidebar-1><i :class="'fa fa-' + (shown ? 'compress' : 'expand')" aria-hidden="true"></i></b-button>
		</div>
		<b-sidebar id="sidebar-1" title="Widgets" shadow v-model="shown">
			<div class="px-3 py-2">
				<b-form-group label="Aggiungi i widgets che vuoi visualizzare sulla dashboard:">
					<b-row v-for="report in reports" v-if="auth.isUserEnable(report.permesso)">
						<b-col cols="9"> {{ report.titolo }} </b-col>
						<b-col>
							<b-button variant="success" size="xs" @click.stop="addWidget(report.permesso)"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
							<b-button variant="primary" size="xs" @click.stop="goTo(report.link)"><i class="fa fa-eye" aria-hidden="true"></i></b-button>
						</b-col>
					</b-row>
				</b-form-group>
				<b-form-group label="Azioni disponibili:" class="mt-3">
					<!--b-button-group vertical-->
						<b-button block class="m-0" size="sm" variant="success" @click="saveReportsWidgets">Salva dashboard</b-button>
						<b-button block class="m-0" size="sm" @click="resetReportsWidgets">Torna alla dashboard di default</b-button>
						<b-button block class="m-0" size="sm" variant="danger" @click="removeAllReportsWidgets">Rimuovi tutti i widgets dalla dashboard</b-button>
						<b-button block class="m-0" size="sm" variant="warning" @click="show_import = !show_import">Importa widgets da altra categoria</b-button>
					<!--/b-button-group-->
				</b-form-group>
				<div v-if="show_import">
					<b-form-group label="Categorie disponibili:" class="mt-3">
						<b-form-select v-model="team_to_import">
							<b-form-select-option :value="null">-- Seleziona --</b-form-select-option>
							<b-form-select-option v-for="team in teams_to_import" :value="team.id">{{ team.categoria.name }}</b-form-select-option>	
						</b-form-select>
					</b-form-group>
					<b-button size="sm" variant="primary" @click="importReportsWidgets" :disabled="!team_to_import">Importa widgets</b-button>
				</div>
				<b-button block size="sm" @click.prevent="$store.commit('changeReportNew', false)" variant="outline-primary" title="Torna alla vecchia versione della dashboard report"> <i class="fa fa-th-large" aria-hidden="true"></i> Vecchia dashboard</b-button>
			</div>
		</b-sidebar>
		<div class="container-fluid m-y-1">
			<b-card no-body class="border-0" style="height: 90vh; overflow-y:scroll;">
				<smart-widget-grid :layout="reports_widgets">
					<smart-widget v-for="report in reports_widgets" :slot="report.i" :title="report.titolo" v-if="auth.isUserEnable(report.permesso)" fullscreen @before-fullscreen="report.fullscreen = !report.fullscreen">
						<template slot="toolbar">
							<div style="margin: 0 12px;">
								<b-button variant="primary" size="sm" @click="goTo(report.link)"><i class="fa fa-eye" aria-hidden="true"></i></b-button>
								<b-button variant="success" size="sm" @click="showConfigWidget(report.i)"><i class="fa fa-pencil" aria-hidden="true"></i></b-button>
								<b-button size="sm" @click="removeWidget(report.i)"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
							</div>
						</template>
						<template v-slot="{contentH}">
							<div class="content-widget">
								<div v-if="report.component" :is="report.component" :contentHeight="contentH" :isWidget="report.i" :isFullscreen="report.fullscreen" :config="report.config" :refresh="report.refresh" @updateConfigWidget="updateConfigWidget" :configWidget="report.configWidget" @updateTitleWidget="updateTitleWidget">
								</div>
								<router-link :to="{ name: report.link }" v-else>
									<div class="p-1">
										<img :alt="report.titolo" :src="report.img" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</router-link>
							</div>
						</template>
					</smart-widget>
				</smart-widget-grid>
			</b-card>
			<b-modal ref="config_widget" title="Configura Widget" @ok="saveReportsWidgets">
				<div v-if="widget_selected">
					<b-form-group label="Titolo">
						<b-form-input type="text" v-model="widget_selected.titolo"/>
					</b-form-group>
					<b-form-group label="Aggiornamento dati (minuti)">
						<b-form-input type="number" v-model="widget_selected.refresh"/>
					</b-form-group>
					<div class="color-rosso">Saranno inoltre salvati sul widget gli attuali filtri/parametri impostati e visualizzati in modalità fullscreen.</div>
					<!--b-form-group label="Parametri">
						<pre>{{ widget_selected.configWidget }}</pre>
					</b-form-group-->
    			</div>
  			</b-modal>
		</div>
		<footerapp></footerapp>
	</section>
</template>
<script>

import pageBase from '@/mixins/pageBase.js';
var Auth = require('@/auth.js');
import { mapState } from 'vuex';
export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			reports: [ 
				{titolo: 'Players Matches', permesso: 'report_players_matches', link: 'reports_players_matches', img: '/assets/screens/player-matches.png', component: 'PlayersMatches'},
				{titolo: 'Players Disciplinary', permesso: 'report_players_yellows', link: 'reports_players_yellows', img: '/assets/screens/player-disciplinary.png', component: 'PlayersYellows'}, 
				{titolo: 'Players On Loan', permesso: 'report_players_onloan', link: 'reports_players_onloan', img: '/assets/screens/player-on-loan.png', component: 'PlayersOnLoan'}, 
				{titolo: 'Scouting Report', permesso: 'report_scouting', link: 'reports_scouting', img: '/assets/screens/scouting.png', component: 'ScoutingReport'}, 
				{titolo: 'Report Recovery', permesso: 'report_recovery', link: 'medical_recovery_dashboard', img: '/assets/screens/recovery.jpeg', component: 'RecoveryDashboard'}, 
				{titolo: 'Report Fisioterapia', permesso: 'report_fisioterapia', link: 'medical_fisioterapia_dashboard', img: '/assets/screens/fisioterapia.png', component: 'DashboardTypeA', config: { title: 'Fisioterapia', itemSingular: 'Fisioterapia',itemPlural: 'Fisioterapie', endpoint: '/atlas/dashboard/fisioterapia', itemIdField: 'fisioterapia_glo_id'}}, 
				{titolo: 'Report Test Fisioterapia', permesso: 'report_test_fisioterapia', link: 'medical_fisioterapia_test_dashboard', img: '/assets/screens/test-fisioterapia.png', component: 'DashboardTypeA', config: { title: 'Test Fisioterapia', itemSingular: 'Test Fisioterapia', itemPlural: 'Test Fisioterapie', endpoint: '/atlas/dashboard/fisioterapia-test', itemIdField: 'fisioterapia_test_glo_id'}}, 
				{titolo: 'Report Nutrition', permesso: 'report_nutrition', link: 'medical_nutrition_dashboard', img: '/assets/screens/nutrition.png', component: 'DashboardTypeA', config: { title: 'Nutrition', itemSingular: 'Nutrition', itemPlural: 'Nutrition', endpoint: '/atlas/dashboard/nutrition', itemIdField: 'nutrition_glo_id'}}, 
				{titolo: 'Report Farmaci', permesso: 'report_farmaco', link: 'medical_farmaco_dashboard', img: '/assets/screens/farmaco.png', component: 'DashboardTypeA', config: { title: 'Farmaco', itemSingular: 'Farmaco', itemPlural: 'Farmaci', endpoint: '/atlas/dashboard/farmaco', itemIdField: 'farmaco_glo_id'}}, 
				{titolo: 'Players Matches Attendances', permesso: 'report_players_matches_attendance', link: 'reports_players_matches_attendances', img: '/assets/screens/attendance.png', component: 'PlayersMatchesAttendances'}, 
				{titolo: 'Report Infortuni', permesso: 'report_infortuni', link: 'medical_injuries_dashboard', img: '/assets/screens/infortuni.png', component: 'InjuriesDashboard'}, 
				{titolo: 'Report Peso', permesso: 'report_peso', link: 'medical_peso_dashboard', img: '/assets/screens/peso.png', component: 'DashboardTypeDebug', config: { endpoint: '/atlas/dashboard/peso'}}, 
				{titolo: 'Report Prevenzione', permesso: 'report_prevenzione', link: 'medical_prevenzione_dashboard', img: '/assets/screens/recovery.jpeg', component: 'PrevenzioneDashboard'},
				{titolo: 'Report Palestra', permesso: 'report_palestra', link: 'palestra_dashboard', img: '/assets/screens/fisioterapia.png', component: 'DashboardTypeA', config: { title: 'Palestra', itemSingular: 'Palestra', itemPlural: 'Palestre', endpoint: '/atlas/dashboard/palestra', itemIdField: 'esercizio_id'}},  
				{titolo: 'Report Sessioni', permesso: 'report_sessioni', link: 'sessioni_dashboard', img: '/assets/screens/fisioterapia.png', component: 'DashboardTypeA', config: { title: 'Sessioni', itemSingular: 'Sessione', itemPlural: 'Sessioni', endpoint: '/atlas/dashboard/sessioni', itemIdField: 'sessione_tipo', uniqBy: 'sessione_id'}},
				{titolo: 'Players Data', permesso: 'medical', link: 'medical_rosa', component: 'PlayersData'}, 
				{titolo: 'Report Fasi', permesso: 'report_glossario', link: 'glossario_dashboard', img: '/assets/screens/fisioterapia.png', component: 'DashboardTypeA', config: { title: 'Fasi', itemSingular: 'Fase', itemPlural: 'Fasi', endpoint: '/atlas/dashboard/glossario', itemIdField: 'glossario_id', uniqBy: 'fase_id', groupBy: 'fase', groupByLabel: 'Tipo di fase'}},
				{titolo: 'Tools Analysis', permesso: 'report_tools_analysis', link: 'reports_tools_analysis', img: '/assets/screens/player-matches.png', component: 'ToolsAnalysis'},
				{titolo: 'Tracking Analysis', permesso: 'report_tracking_analysis', link: 'reports_tracking_analysis', img: '/assets/screens/player-matches.png', component: 'ToolsTrackingAnalysis'},
				{titolo: 'Sessioni Prevenzione', permesso: 'report_sessioni_prevenzione', link: 'reports_sessioni_prevenzione', img: '/assets/screens/player-matches.png', component: 'SessioniPrevenzione'},
				{titolo: 'Minutaggio Partite', permesso: 'report_minutaggio_partite', link: 'reports_minutaggio_partite', img: '/assets/screens/player-on-loan.png', component: 'MinutaggioPartite'}, 
				],
			shown: false,
			reports_widgets: [],
			show_import: false,
			team_to_import: null,
			widget_selected: null,
		}
	},
	components: {
		'PlayersMatches': require('@/components/layout/reports/PlayersMatches.vue').default,
		'PlayersYellows': require('@/components/layout/reports/PlayersYellows.vue').default,
		'PlayersOnLoan': require('@/components/layout/reports/PlayersOnLoan.vue').default,
		'PlayersMatchesAttendances': require('@/components/layout/reports/PlayersMatchesAttendances.vue').default,
		'DashboardTypeDebug': require('@/components/layout/reports/DashboardTypeDebug.vue').default,
		'DashboardTypeA': require('@/components/layout/reports/DashboardTypeA.vue').default,
		'InjuriesDashboard': require('@/components/layout/reports/InjuriesDashboard.vue').default,
		'ScoutingReport': require('@/components/layout/reports/ScoutingReport.vue').default,
		'RecoveryDashboard': require('@/components/layout/reports/RecoveryDashboard.vue').default,
		'PrevenzioneDashboard': require('@/components/layout/reports/PrevenzioneDashboard.vue').default,
		'PlayersData': require('@/components/layout/widgets/PlayersData.vue').default,
		'ToolsAnalysis': require('@/components/layout/Sessioni/Generica/Tools_Table.vue').default,
		'ToolsTrackingAnalysis': require('@/components/layout/Sessioni/Generica/Tools_Tracking_Analysis.vue').default,
		'SessioniPrevenzione': require('@/components/layout/reports/SessioniPrevenzione.vue').default,
		'MinutaggioPartite': require('@/components/layout/reports/MinutaggioPartite.vue').default,
	},
	computed: {
		auth: function() {
			return Auth;
		},
		teams_to_import: function() {
			return this.auth.user.rosaTeams.filter(item => item.id != this.rosaTeamId && this.auth.presets()['reportsWidgets_' + item.id] != undefined);
		},
		...mapState(['rosaTeamId']),
	},
	watch: {
		rosaTeamId() {
			this.getReportsWidgets();
		},	
	},
	methods: {
		goTo: function(where) {
			this.$router.push({name: where});	
		},
		saveReportsWidgets: function() {
			if(confirm("Confermi di voler salvare la configurazione attuale della dashboard ?")) {
				this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/set-persone', { chiave: 'reportsWidgets_' + this.rosaTeamId, persone: this.reports_widgets }).then(function(data) {
					this.auth.refreshUser(this.$root.$children[0]);
					this.alertPush("Dashboard salvata con successo!", 1);
				});
			}
		},
		resetReportsWidgets: function() {
			if(confirm("Confermi di voler resettare la dashboard alla configurazione iniziale di default ? L'azione è comunque reversibile (basta aggiornare la pagina) fino a che non viene salvata la nuova configurazione della dashboard.")) {
				this.reports_widgets = [];
				var x = 0;
				var y = 0;
				for(var i in this.reports) {
					var report = this.reports[i];
					if(this.auth.isUserEnable(report.permesso)) {
						this.reports_widgets.push({ x: x, y: y, w: 4, h: 8, i: report.permesso + '_' + Math.random(), titolo: report.titolo, permesso: report.permesso, link: report.link, img: report.img, component: report.component ? report.component : null, fullscreen: false, config: report.config ? report.config : null, refresh: 0, configWidget: {} });
					}
					if(x == 8) {
						x = 0;
						y = y + 8;
					} else {
						x = x + 4;
					}
				}
			}
		},
		getReportsWidgets: function() {
			if(this.auth.presets()['reportsWidgets_' + this.rosaTeamId] != undefined) {
				this.reports_widgets = _.cloneDeep(this.auth.presets()['reportsWidgets_' + this.rosaTeamId]);
				for(var i in this.reports_widgets) {
					this.reports_widgets[i].fullscreen = false;
				}
			} else {
				this.reports_widgets = [];
				var x = 0;
				var y = 0;
				for(var i in this.reports) {
					var report = this.reports[i];
					if(this.auth.isUserEnable(report.permesso)) {
						this.reports_widgets.push({ x: x, y: y, w: 4, h: 8, i: report.permesso + '_' + Math.random(), titolo: report.titolo, permesso: report.permesso, link: report.link, img: report.img, component: report.component ? report.component : null, fullscreen: false, config: report.config ? report.config : null, refresh: 0, configWidget: {} });
					}
					if(x == 8) {
						x = 0;
						y = y + 8;
					} else {
						x = x + 4;
					}
				}
			}
		},
		addWidget: function(permesso) {
			var countWidgets = this.reports_widgets.length;
			var x = 0;
			var y = 0;
			for(var i = 0; i < countWidgets; i++) {
				if(x == 8) {
					x = 0;
					y = y + 8;
				} else {
					x = x + 4;
				}
			}
			var report = this.reports.find(item => item.permesso == permesso);
			if(report && this.auth.isUserEnable(report.permesso)) {
				this.reports_widgets.push({ x: x, y: y, w: 4, h: 8, i: report.permesso + '_' + Math.random(), titolo: report.titolo, permesso: report.permesso, link: report.link, img: report.img, component: report.component ? report.component : null, fullscreen: false, config: report.config ? report.config : {}, refresh: 0, configWidget: {} });
			}
		},
		removeWidget: function(key) {
			if(confirm("Confermi di voler eliminare il widget ?")) {
				var findIndex = this.reports_widgets.findIndex(item => item.i == key);
				if(findIndex > -1) {
					this.reports_widgets.splice(findIndex, 1);
				}
			}	
		},
		removeAllReportsWidgets: function() {
			if(confirm("Confermi di voler eliminare tutti i widgets dalla dashboard ? L'azione è comunque reversibile (basta aggiornare la pagina) fino a che non viene salvata la nuova configurazione della dashboard.")) {
				this.reports_widgets = [];		
			}	
		},
		importReportsWidgets: function() {
			if(confirm("Confermi di voler importare tutti i widgets dalla categoria selezionata ? L'azione è comunque reversibile (basta aggiornare la pagina) fino a che non viene salvata la nuova configurazione della dashboard.")) {
				if(this.auth.presets()['reportsWidgets_' + this.team_to_import] != undefined) {
					this.reports_widgets = _.cloneDeep(this.auth.presets()['reportsWidgets_' + this.team_to_import]);
					for(var i in this.reports_widgets) {
						this.reports_widgets[i].fullscreen = false;
					}
				}
				this.show_import = false;	
			}	
		},
		showConfigWidget: function(key) {
			var findIndex = this.reports_widgets.findIndex(item => item.i == key);
			if(findIndex > -1) {
				this.widget_selected = this.reports_widgets[findIndex];
				this.$refs.config_widget.show();	
			}	
		},
		updateConfigWidget: function(id, key, value) {
			var findIndex = this.reports_widgets.findIndex(item => item.i == id);
			if(findIndex > -1) {
				if(!this.reports_widgets[findIndex].configWidget || (this.reports_widgets[findIndex].configWidget && Array.isArray(this.reports_widgets[findIndex].configWidget) && this.reports_widgets[findIndex].configWidget.length == 0)) {
					this.reports_widgets[findIndex].configWidget = {};
				}
				this.reports_widgets[findIndex].configWidget[key] = value;
			}
		},
		updateTitleWidget: function(id, value) {
			var findIndex = this.reports_widgets.findIndex(item => item.i == id);
			if(findIndex > -1) {
				this.reports_widgets[findIndex].titolo = value;
			}
		},
	},
	created: function() {
		this.getReportsWidgets();
	},
};

</script>
<style>
.content-widget {
	font-size: 0.8rem;
}
.smartwidget.smartwidget-fullscreen {
	z-index: 1000 !important;
}
</style>