<template>
	<div>
		<b-card>
			<template slot="header">
				<b-row align-v="center"><b-col><strong>Impact on the game</strong></b-col><b-col cols="2" class="pr-2"><Smart-Tag :readonly="read" :value="evaluation.evaluation.gruppo_1.valore_0.value" :disabled="true"></Smart-Tag></b-col></b-row>
			</template>
			<div>
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th colspan="2">Resolving problems created by the opponent</b-th>
							<!-- <b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_1.note" /></b-td> -->
							<b-td><Smart-Tag :readonly="read" :disabled="true" v-model="evaluation.evaluation.gruppo_1.valore_1.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Defending 1vs1 situations (short and long)</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_2.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_2.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to dispossess</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_3.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_3.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Defending opponent back to goal</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_4.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_4.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Defending the space in behind</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_5.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_5.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Defending the box</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_6.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_6.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Reaction at ball loss</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_7.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_7.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Defending quick transitions</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_8.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_8.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th></b-th>
							<b-th width="10%">Val</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th colspan="2">Creating problems to the opponent</b-th>
							<!-- <b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_9.note" /></b-td> -->
							<b-td><Smart-Tag :readonly="read" :disabled="true" v-model="evaluation.evaluation.gruppo_1.valore_9.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Angles to receive</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_10.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_10.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Impact on ball circulation (continuity / verticality)</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_11.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_11.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Combination play</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_12.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_12.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to eliminate</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_13.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_13.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to attack wide</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_14.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_14.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to attack inside</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_15.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_15.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to be decisive</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_16.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_16.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th></b-th>
							<b-th width="10%">Val</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th colspan="2">Interpretation of game situations</b-th>
							<!-- <b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_17.note" /></b-td> -->
							<b-td><Smart-Tag :disabled="true" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_17.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Reaction in open spaces</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_18.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_18.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Marking</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_19.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_19.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to anticipate / Step in front of his man</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_20.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_20.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Coverings</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_21.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_21.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Scanning / Awareness</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_22.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_22.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Movements and capacity to lose the mark</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_23.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_23.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Composure under pressure</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_24.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_24.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Decision making</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_25.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_25.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">
				<b-row align-v="center"><b-col><strong>Physical Profile</strong></b-col><b-col cols="2" class="pr-2"><Smart-Tag :readonly="read" :value="evaluation.evaluation.gruppo_2.valore_0.value" :disabled="true"></Smart-Tag></b-col></b-row>
			</template>
			<div>
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Pace / Turn of pace / Acceleration</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_1.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_1.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Activation / Reactions</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_2.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_2.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Power</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_3.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_3.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to impose himself in contact duels</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_4.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_4.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to repeat high intensity runs / Generosity</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_5.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_5.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>In the air</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_6.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_6.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Cooordination / Balance / Agility</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_7.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_7.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>

					</b-tbody>
				</b-table-simple>
			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">
				<b-row align-v="center"><b-col><strong>Technical Profile</strong></b-col><b-col cols="2" class="pr-2"><Smart-Tag :readonly="read" :value="evaluation.evaluation.gruppo_3.valore_0.value" :disabled="true"></Smart-Tag></b-col></b-row>
			</template>
			<div>
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Receiving skills / Quality of the 1st touch</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_1.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_1.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Ball manipulation</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_2.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_2.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Passing range (short-medium-long)</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_3.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_3.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Attacking end product</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_4.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_4.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Defensive clearances</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_5.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_5.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Use of weaker foot</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_6.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_6.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Set pieces deliveries</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_7.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_7.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">
				<b-row align-v="center"><b-col><strong>Psychological Profile</strong></b-col><b-col cols="2" class="pr-2"><Smart-Tag :readonly="read" :value="evaluation.evaluation.gruppo_4.valore_0.value" :disabled="true"></Smart-Tag></b-col></b-row>
			</template>
			<div>
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Interactions with the different actors of the game</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_1.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_1.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Body language</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_2.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_2.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Reaction after key moments</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_3.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_3.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Presence / Aura</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_4.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_4.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header"><strong>Conclusion</strong></template>
			<div>
				<b-row>
					<b-col><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_0.valore_0.note"></b-form-textarea></b-col>
					<b-col cols="2"><Smart-Tag :readonly="read" :value="evaluation.evaluation.gruppo_0.valore_0.value" :disabled="true"></Smart-Tag></b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>

export default {

	props: [ 'evaluation', 'read' ],
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
	},
	created: function() {
		if(this.evaluation.evaluation == null) {
			this.$set(this.evaluation, 'evaluation', {
				gruppo_0: {
					valore_0: { value: null, note: null },
				},
				gruppo_1: {
					valore_0: { value: null, note: null },
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null },
					valore_4: { value: null, note: null },
					valore_5: { value: null, note: null },
					valore_6: { value: null, note: null },
					valore_7: { value: null, note: null },
					valore_8: { value: null, note: null },
					valore_9: { value: null, note: null },
					valore_10: { value: null, note: null },
					valore_11: { value: null, note: null },
					valore_12: { value: null, note: null },
					valore_13: { value: null, note: null },
					valore_14: { value: null, note: null },
					valore_15: { value: null, note: null },
					valore_16: { value: null, note: null },
					valore_17: { value: null, note: null },
					valore_18: { value: null, note: null },
					valore_19: { value: null, note: null },
					valore_20: { value: null, note: null },
					valore_21: { value: null, note: null },
					valore_22: { value: null, note: null },
					valore_23: { value: null, note: null },
					valore_24: { value: null, note: null },
					valore_25: { value: null, note: null }
				},
				gruppo_2: {
					valore_0: { value: null, note: null },
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null },
					valore_4: { value: null, note: null },
					valore_5: { value: null, note: null },
					valore_6: { value: null, note: null },
					valore_7: { value: null, note: null }
				},
				gruppo_3: {
					valore_0: { value: null, note: null },
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null },
					valore_4: { value: null, note: null },
					valore_5: { value: null, note: null },
					valore_6: { value: null, note: null },
					valore_7: { value: null, note: null }
				},
				gruppo_4: {
					valore_0: { value: null, note: null },
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null },
					valore_4: { value: null, note: null }
				}
			});
		}
	},
	watch: {
		'evaluation.evaluation': {
			deep: true,
			handler: function() {

				if(this.evaluation.evaluation) {
					_.each(_.keys(this.evaluation.evaluation), function(k) {
						
						if(k == 'gruppo_1') {

							var u = _.filter([ 
								this.evaluation.evaluation.gruppo_1.valore_2.value, 
								this.evaluation.evaluation.gruppo_1.valore_3.value, 
								this.evaluation.evaluation.gruppo_1.valore_4.value,	
								this.evaluation.evaluation.gruppo_1.valore_5.value,
								this.evaluation.evaluation.gruppo_1.valore_6.value, 
								this.evaluation.evaluation.gruppo_1.valore_7.value,
								this.evaluation.evaluation.gruppo_1.valore_8.value
							], function(a) { return a !== null && !isNaN(a) });
							this.evaluation.evaluation.gruppo_1.valore_1.value = Math.round(_.sum(u) / u.length * 100) / 100;

							var u = _.filter([ 
								this.evaluation.evaluation.gruppo_1.valore_10.value,
								this.evaluation.evaluation.gruppo_1.valore_11.value,
								this.evaluation.evaluation.gruppo_1.valore_12.value,
								this.evaluation.evaluation.gruppo_1.valore_13.value,
								this.evaluation.evaluation.gruppo_1.valore_14.value,
								this.evaluation.evaluation.gruppo_1.valore_15.value,
								this.evaluation.evaluation.gruppo_1.valore_16.value
							], function(a) { return a !== null && !isNaN(a) });
							this.evaluation.evaluation.gruppo_1.valore_9.value = Math.round(_.sum(u) / u.length * 100) / 100;

							var u = _.filter([ 
								this.evaluation.evaluation.gruppo_1.valore_18.value,
								this.evaluation.evaluation.gruppo_1.valore_19.value,
								this.evaluation.evaluation.gruppo_1.valore_20.value,
								this.evaluation.evaluation.gruppo_1.valore_21.value,
								this.evaluation.evaluation.gruppo_1.valore_22.value,
								this.evaluation.evaluation.gruppo_1.valore_23.value,
								this.evaluation.evaluation.gruppo_1.valore_24.value,
								this.evaluation.evaluation.gruppo_1.valore_25.value,
							], function(a) { return a !== null && !isNaN(a) });
							this.evaluation.evaluation.gruppo_1.valore_17.value = Math.round(_.sum(u) / u.length * 100) / 100;

							var u = _.filter([ 
								this.evaluation.evaluation.gruppo_1.valore_1.value, 
								this.evaluation.evaluation.gruppo_1.valore_9.value,
								this.evaluation.evaluation.gruppo_1.valore_17.value
							]);
							this.evaluation.evaluation.gruppo_1.valore_0.value = Math.round(_.sum(u) / u.length * 100) / 100;

						} else if(k !== 'gruppo_0') {

							var u = _.filter(this.evaluation.evaluation[k], function(v,kk) {
								if(kk == 'valore_0') return false;
								if(v.value === null) return false;
								if(isNaN(v.value)) return false;
								return true;
							});

							var v = _.filter(_.map(u, 'value'));

							this.evaluation.evaluation[k].valore_0.value = Math.round(_.sum(v)/v.length * 100) / 100;

						}

					}.bind(this));

					var complessivo = _.filter(_.map(_.filter(this.evaluation.evaluation, function(k, v) {
						if(v !== 'gruppo_0')
							return true;
					}), 'valore_0.value'));

					this.evaluation.evaluation.gruppo_0.valore_0.value = Math.round(_.sum(complessivo)/complessivo.length * 100) / 100;

				}

			}
		} 
	}
}

</script>