<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<TrackingAnalysis />
		<footerapp></footerapp>
	</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
export default {
	mixins: [ pageBase ],
	components: {
		'TrackingAnalysis': require('@/components/layout/Sessioni/Generica/Tools_Tracking_Analysis.vue').default
	},
};
</script> 
