<template>
	<div>
		<b-overlay :show="ux.waiting">
			<b-card class="pppp" v-if="!ux.collapsed" :no-body="isWidget ? true : false" :style="(isWidget ? 'border:none;' : '') + (contentHeight && isFullscreen ? 'max-height:' + contentHeight + 'px;overflow-y:auto;overflow-x:hidden;' : '')">
				
				<template #header v-if="!isWidget" header-tag="nav" style="padding: 0;">
					<b-row>
						<b-col>
							Tracking Analysis
						</b-col>
						<b-col v-if="collapsable" class="text-right">
							
							<span v-if="ux.view == 'table' && results && finalData.startingDataset.length > 0 && auth.isUserEnable('tracking_analysis')"><a href="javascript:;" @click.prevent="printSession">Stampa Sessione</a> • </span>
							<span v-if="ux.view == 'radar' && results && finalData.startingDataset.length > 0 && auth.isUserEnable('tracking_analysis')"><a href="javascript:;" @click.prevent="printSessionRadar">Stampa Radar</a> • </span>
							<a href="javascript:;" @click.prevent="ux.collapsed = true">Chiudi</a>

						</b-col>
					</b-row>
					<b-nav class="mt-2" card-header tabs style="margin-bottom: -20px">
						<b-nav-item @click.prevent="ux.view = 'table'" :active="ux.view == 'table'">Tabella</b-nav-item>
						<b-nav-item v-if="sessione.sessione_tipo_glo_id == 15" @click.prevent="ux.view = 'radar'" :active="ux.view == 'radar'">Grafici Radar</b-nav-item>
						<b-checkbox v-if="sessione.sessione_tipo_glo_id != 15" style="margin-top: 9px; margin-left: 9px" type="checkbox" switch v-model="ux.wholeSessione"> Allenamento Completo</b-checkbox>
					</b-nav>
				</template>

				<!--button @click.prevent="fetchResults">!</button-->
				<div v-if="results && ux.view == 'radar'">
					<b-row>
						<b-col class="mb-2" cols="4" v-for="rdata, u in radarData">
							<b-card>
								<template slot="header">{{ rdata.player.cognome }}</template>
								<E-Chart :key="'p' + u" style="width: 100%; height: 300px" :option="rdata"></E-Chart>
								<b-row style="column-gap: 6px;" class="mt-2">
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase;">Durata <small class="text-muted">min</small></div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ _.round(rdata.playerRaw.total_duration / 60, 0) }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.total_duration/rdata.bestRaw.total_duration))">{{ _.round(100 * (rdata.playerRaw.total_duration/rdata.bestRaw.total_duration), 0) }} %</small></div>
										</div>
									</b-col>
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase;">Dist. Totale</div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ rdata.playerRaw.total_distance }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.total_distance/rdata.bestRaw.total_distance))">{{ _.round(100 * (rdata.playerRaw.total_distance/rdata.bestRaw.total_distance), 0) }} %</small></div>
										</div>
									</b-col>
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase;">Dist Rel</div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ rdata.playerRaw.relative_distance }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.relative_distance/rdata.bestRaw.relative_distance))">{{ _.round(100 * (rdata.playerRaw.relative_distance/rdata.bestRaw.relative_distance), 0) }} %</small></div>
										</div>
									</b-col>
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase;">Vel Max.</div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ rdata.playerRaw.max_vel }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.max_vel/rdata.bestRaw.max_vel))">{{ _.round(100 * (rdata.playerRaw.max_vel/rdata.bestRaw.max_vel), 0) }} %</small></div>
										</div>
									</b-col>
								</b-row>
								<b-row style="column-gap: 6px;" class="mt-2">
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase; white-space: nowrap">D 20-25km/h</div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ rdata.playerRaw.velocity_20_25 }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.velocity_20_25/rdata.bestRaw.velocity_20_25))">{{ _.round(100 * (rdata.playerRaw.velocity_20_25/rdata.bestRaw.velocity_20_25), 0) }} %</small></div>
										</div>
									</b-col>
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase; white-space: nowrap">D > 20km/h</div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ rdata.playerRaw.velocity__20 }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.velocity__20/rdata.bestRaw.velocity__20))">{{ _.round(100 * (rdata.playerRaw.velocity__20/rdata.bestRaw.velocity__20), 0) }} %</small></div>
										</div>
									</b-col>
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase;">Acc.</div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ rdata.playerRaw.num_acc }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.num_acc/rdata.bestRaw.num_acc))">{{ _.round(100 * (rdata.playerRaw.num_acc/rdata.bestRaw.num_acc), 0) }} %</small></div>
										</div>
									</b-col>
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase;">Dec.</div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ rdata.playerRaw.num_dec }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.num_dec/rdata.bestRaw.num_dec))">{{ _.round(100 * (rdata.playerRaw.num_dec/rdata.bestRaw.num_dec), 0) }} %</small></div>
										</div>
									</b-col>
									<b-col style="padding: 5px; line-height: 1; border: 1px solid rgba(0,0,0,.1); border-radius: 5px; background-color: rgba(0,0,0,.03)">
										<div class="small" style="text-transform: uppercase;">RPE</div>
										<div class="mt-1" style="font-size: 105%; font-weight: bold;">
											{{ rdata.playerRaw.rpe }}
											<div><small class="float-right p-1" :style="playerColor(100 * (rdata.playerRaw.rpe/rdata.bestRaw.rpe))">{{ _.round(100 * (rdata.playerRaw.rpe/rdata.bestRaw.rpe), 0) }} %</small></div>
										</div>
									</b-col>
								</b-row>
							</b-card>
						</b-col>
					</b-row>

				</div>
				<div v-if="results && ux.view == 'table'">

					<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
						<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
							<b-tr>
								<b-th width="16%"></b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Tempo</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Distanza</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D. Rel</div>
									<div class="text-center small text-muted" style="line-height: 1">metri/min</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>15km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>20km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D20-25km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>25km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Power Events</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">N ACC</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">N DEC</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Vel Max</div>
									<div class="text-center small text-muted" style="line-height: 1">km/h</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">%ED</div>
									<div class="text-center small text-muted" style="line-height: 1">%</div>
								</b-th>
								<b-th width="12%" colspan="2"></b-th>
								
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="item in finalData.startingDataset">
								<b-td v-html="item.match"></b-td>
								<b-td class="text-center">{{ item.tracking.total_duration | formatTime }}</b-td>
								<b-td class="text-center">{{ item.tracking.total_distance }}</b-td>
								<b-td class="text-center">{{ item.tracking.relative_distance }}</b-td>
								<b-td class="text-center">{{ item.tracking.velocity__15 }}</b-td>
								<b-td class="text-center">{{ item.tracking.velocity__20 }}</b-td>
								<b-td class="text-center">{{ item.tracking.velocity_20_25 }}</b-td>
								<b-td class="text-center">{{ item.tracking.velocity__25 }}</b-td>
								<b-td class="text-center">{{ item.tracking.pow_ev }}</b-td>
								<b-td class="text-center">{{ item.tracking.num_acc }}</b-td>
								<b-td class="text-center">{{ item.tracking.num_dec }}</b-td>
								<b-td class="text-center">{{ item.tracking.max_vel }}</b-td>
								<b-td class="text-center">{{ item.tracking.edi }}</b-td>
								<b-td colspan="2"></b-td>
							
							</b-tr>
						</b-tbody>
						<b-tfoot>
							<tr>
								<td colspan="2"></td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.total_distance, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.total_distance))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.total_distance, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.total_distance) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.relative_distance, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.relative_distance))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.relative_distance, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.relative_distance) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__15, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__15))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__15, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__15) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__20, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__20))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__20, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__20) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity_20_25, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity_20_25))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity_20_25, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity_20_25) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__25, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__25))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__25, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__25) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.pow_ev, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.pow_ev))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.pow_ev, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.pow_ev) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.num_acc, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.num_acc))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.num_acc, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.num_acc) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.num_dec, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.num_dec))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.num_dec, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.num_dec) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.max_vel, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.max_vel))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.max_vel, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.max_vel) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.edi, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.edi))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.edi, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.edi) }} %</td>
							</tr>
						</b-tfoot>
					</b-table-simple>

					<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
						<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
							<b-tr>
								<b-th width="16%" class="text-right" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'persona.cognome' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('persona.cognome')">Player</a></b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'total_duration' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('total_duration')">Tempo</a></div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'total_distance' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('total_distance')">Distanza</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'relative_distance' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('relative_distance')">D. Rel</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri/min</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__15' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__15')">D>15km/h</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__20' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__20')">D>20km/h</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity_20_25' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity_20_25')">D20-25km/h</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__25' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__25')">D>25km/h</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'pow_ev' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('pow_ev')">Power Events</a></div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'num_acc' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('num_acc')">N ACC</a></div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'num_dec' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('num_dec')">N DEC</a></div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'max_vel' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('max_vel')">Vel Max</a></div>
									<div class="text-center small text-muted" style="line-height: 1">km/h</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'edi' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('edi')">%ED</a></div>
									<div class="text-center small text-muted" style="line-height: 1">%</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'tl___' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('tl___')">Session</a></div>
									<div class="text-center small text-muted" style="line-height: 1">min</div>
								</b-th>
								<b-th class="text-center" width="6%"><a href="javascript:;" :style="ux.orderBy == 'rpe' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('rpe')">RPE</a>
									<div class="text-center small text-muted" style="line-height: 1">ua</div>
								</b-th>
								<b-th class="text-center" width="6%"><a href="javascript:;" :style="ux.orderBy == 'tl' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('tl')">TL</a>
									<div class="text-center small text-muted" style="line-height: 1">ua</div>
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="item in finalData.current" v-if="item.total_duration > 0" :variant="item.persona.team ? 'info' : ''">
								<b-td class="text-right"  style="text-transform: uppercase">{{ item.persona.cognome }}</b-td>
								<b-td class="text-center"><span v-if="!ux.wholeSessione">{{ item.total_duration | formatTime }}</span><span v-else>{{ (item.tl___ * 60) | formatTime }}</span></b-td>
								<b-td class="text-center" :variant="isBest(item.total_distance, 'total_distance') ? 'success' : (isWorst(item.total_distance, 'total_distance') ? 'danger' : '')">{{ item.total_distance }}</b-td>
								<b-td class="text-center" :variant="isBest(item.relative_distance, 'relative_distance') ? 'success' : (isWorst(item.relative_distance, 'relative_distance') ? 'danger' : '')">{{ item.relative_distance }}</b-td>
								<b-td class="text-center" :variant="isBest(item.velocity__15, 'velocity__15') ? 'success' : (isWorst(item.velocity__15, 'velocity__15') ? 'danger' : '')">{{ item.velocity__15 }}</b-td>
								<b-td class="text-center" :variant="isBest(item.velocity__20, 'velocity__20') ? 'success' : (isWorst(item.velocity__20, 'velocity__20') ? 'danger' : '')">{{ item.velocity__20 }}</b-td>
								<b-td class="text-center" :variant="isBest(item.velocity_20_25, 'velocity_20_25') ? 'success' : (isWorst(item.velocity_20_25, 'velocity_20_25') ? 'danger' : '')">{{ item.velocity_20_25 }}</b-td>
								<b-td class="text-center" :variant="isBest(item.velocity__25, 'velocity__25') ? 'success' : (isWorst(item.velocity__25, 'velocity__25') ? 'danger' : '')">{{ item.velocity__25 }}</b-td>
								<b-td class="text-center" :variant="isBest(item.pow_ev, 'pow_ev') ? 'success' : (isWorst(item.pow_ev, 'pow_ev') ? 'danger' : '')">{{ item.pow_ev }}</b-td>
								<b-td class="text-center" :variant="isBest(item.num_acc, 'num_acc') ? 'success' : (isWorst(item.num_acc, 'num_acc') ? 'danger' : '')">{{ item.num_acc }}</b-td>
								<b-td class="text-center" :variant="isBest(item.num_dec, 'num_dec') ? 'success' : (isWorst(item.num_dec, 'num_dec') ? 'danger' : '')">{{ item.num_dec }}</b-td>
								<b-td class="text-center" :variant="isBest(item.max_vel, 'max_vel') ? 'success' : (isWorst(item.max_vel, 'max_vel') ? 'danger' : '')">{{ item.max_vel }}</b-td>
								<b-td class="text-center" :variant="isBest(item.edi, 'edi') ? 'success' : (isWorst(item.edi, 'edi') ? 'danger' : '')">{{ item.edi }}</b-td>
								<b-td class="text-center">{{ (item.tl___ * 60) | formatTime }}</b-td>
								<b-td class="text-center" :variant="isBest(item.rpe, 'rpe') ? 'success_' : (isWorst(item.rpe, 'rpe') ? 'danger_' : '')">{{ item.rpe }}</b-td>
								<b-td class="text-center" :variant="isBest(item.tl, 'tl') ? 'success_' : (isWorst(item.tl, 'tl') ? 'danger_' : '')">{{ item.tl }}</b-td>
							</b-tr>
						</b-tbody>
						<b-tfoot>
						</b-tfoot>
					</b-table-simple>		

				</div>

			</b-card>
			<span v-else-if="collapsable">
				<b-button size="sm" variant="info" block @click.prevent="ux.collapsed = false">Apri Tracking Analysis</b-button>
			</span>
		</b-overlay>
	</div>
</template>

<script>


var Auth = require('@/auth.js');
import moment from 'moment';

var fields = [
	'total_duration',
	'total_distance',
	'relative_distance',
	'velocity__15',
	'velocity__20',
	'velocity_20_25',
	'velocity__25',
	'pow_ev',
	'num_acc',
	'num_dec',
	'max_vel',
	'edi',
	'rpe',
	'tl',
	'tl___',
];

function calculateMean(a, isMatch, full = false) {

	var dataset = _.cloneDeep(a);
	console.log('PPD', full, dataset);

	if(_.size(dataset) > 10 && isMatch)
		var amounts = 10;//_.size(dataset);
	else
		var amounts = _.size(dataset);
	
	var out = {};

	console.log('PPD', dataset);

	_.each(fields, function(u) {

		console.log('• ', _.map(dataset, u));
		var p = _.filter(_.map(dataset, u));

		console.log('!Amount of dataset: ' + u + ' ' + _.size(p) + ' - The divider for the view is: ' + amounts, _.filter(_.map(dataset, 'persona_id')));

		if(u == 'max_vel' || u == 'edi' || u == 'tl' || u == 'tl___' || (!isMatch && u == 'total_duration')) {
			out[u] = _.round(_.sumBy(dataset, u)/_.size(p), (u == 'max_vel' || u == 'edi' ? 1 : 0));
		} else if(u == 'rpe')
			out[u] = _.round(_.sumBy(dataset, u)/_.size(p), 2);
		else
			out[u] = _.round(_.sumBy(dataset, u)/amounts, (u == 'max_vel' || u == 'edi' ? 1 : 0), 1);

	});

	if(!full)
		out.relative_distance = _.round(out.total_distance/(out.total_duration/60),0)
	else
		out.relative_distance = _.round(out.total_distance/(out.tl___),0)

	return out;

}

function calculateMeans(dataset, isMatch, full) {

	var u = _.cloneDeep(dataset);
	console.log(full, 'Was', _.map(dataset.tracking, 'persona_id'));

	var hasPortieri = _.map(_.pickBy(u.persone, function(c) {
		return _.get(c, 'last_carriera_sportiva.ruolo_ideale[0].sigla') === 'PO';
	}), 'id');

	// console.log(hasPortieri);

	var tracking = _.filter(u.tracking, function(p) {
		return hasPortieri.indexOf(p.persona_id) === -1;
	});

	console.log('Here Dataset - A3');
	u.tracking = calculateMean(tracking, isMatch, full);
	return u;

}

import eChart from '@/components/layout/E-Chart/Base.vue';

export default {
	components: {
		'E-Chart': eChart,
	},
	props: [ 'collapsable', 'sessione', 'isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	filters: {
		formatTime: function(seconds) {
			return moment.utc(moment.duration(seconds,'seconds').as('milliseconds')).format('HH:mm:ss');
		}
	},
	computed: {
		_: function() {
			return _;
		},
		radarData: function() {

			var mean = _.find(this.finalData.current, function(u) { return u.persona.team && u.persona.team == true });
			var others = _.reject(this.finalData.current, function(u) { return (u.persona.team && u.persona.team == true) || u.total_duration == 0 || u.total_duration == null });

			var meanValues = [
				mean.total_distance,
				mean.relative_distance,
				mean.velocity__15,
				mean.velocity__20,
				mean.velocity_20_25,
				mean.velocity__25,
				mean.pow_ev,
				mean.num_acc,
				mean.num_dec,
				mean.max_vel,
				mean.edi,
				mean.rpe,
				mean.tl
			];

			var outputRadar = [];

			_.each(others, function(i) {

				if(!i.total_duration) return false;

				var playerValues = [
					i.total_distance,
					i.relative_distance,
					i.velocity__15,
					i.velocity__20,
					i.velocity_20_25,
					i.velocity__25,
					i.pow_ev,
					i.num_acc,
					i.num_dec,
					i.max_vel,
					i.edi,
					i.rpe,
					i.tl
				];

				var playerBest = [
					this.results.bests[i.persona.id].total_distance,
					this.results.bests[i.persona.id].relative_distance,
					this.results.bests[i.persona.id].velocity__15,
					this.results.bests[i.persona.id].velocity__20,
					this.results.bests[i.persona.id].velocity_20_25,
					this.results.bests[i.persona.id].velocity__25,
					this.results.bests[i.persona.id].pow_ev,
					this.results.bests[i.persona.id].num_acc,
					this.results.bests[i.persona.id].num_dec,
					this.results.bests[i.persona.id].max_vel,
					this.results.bests[i.persona.id].edi,
					this.results.bests[i.persona.id].rpe,
					this.results.bests[i.persona.id].tl
				];

				// console.log(i.persona.cognome);
				// console.log(playerValues, playerBest);

				var title = this.results.partita.home.name + ' - ' + ' ' + this.results.partita.away.name; //_.truncate(i.persona.cognome, { length: 12 });
				console.log(this.results.bests[i.persona.id]);
				outputRadar.push({
					player: i.persona,
					bestRaw: this.results.bests[i.persona.id],
					total_duration: i.total_duration,
					playerRaw: i,
					legend: {
						data: [ title, 'Mod. Prestativo' ]
					},
					radar: {
						center: ['50%', '55%'],
						radius: '75%',
						indicator: [
							{ name: 'Distanza', min: 0 },
							{ name: 'D. Rel', min: 0 },
							{ name: 'D>15km/h', min: 0 },
							{ name: 'D>20km/h', min: 0 },
							{ name: 'D20-25km/h', min: 0 },
							{ name: 'D>25km/h', min: 0 },
							{ name: 'Power Events', min: 0 },
							{ name: 'N ACC', min: 0 },
							{ name: 'N DEC', min: 0 },
							{ name: 'Vel Max', min: 0 },
							{ name: '%ED', min: 0 },
							{ name: 'RPE', min: 0, max: 15 },
							{ name: 'TL', min: 0 }
						]
					},
					series: [
						{
							type: 'radar',
							data: [
								{
									value: playerValues,
									name: title,
									itemStyle: {
										color: '#a31c30',
									},
									tooltip: {
										trigger: 'item'
									},
									areaStyle: {
										opacity: 0.07
									}
								},
								{
									value: playerBest,
									name: 'Mod. Prestativo',
									itemStyle: {
										color: '#fbba00',
									},
									tooltip: {
										trigger: 'item'
									},
									areaStyle: {
										opacity: 0.07
									}
								}
							]
						}
					]
				});

			}.bind(this));

			return outputRadar;

		},
		auth: function() {
			return Auth;
		},
		isMatch: function() {
			return this.sessione && this.sessione.sessione_tipo_glo_id == 15;
		},
		bests: function() {

			var ruoli = _.map(this.finalData.current, 'persona.last_carriera_sportiva.ruolo_ideale[0].sigla');
			var realCurrent = _.filter(_.map(_.cloneDeep(this.finalData.current), function(e, b) {
				if(ruoli[b] === 'PO') return false;
				if(e.persona && e.persona.team !== undefined) return false;
				if(e.total_duration == 0 || e.total_duration == null) return false;
				return e;
			}));

			var a = {};
			_.each(fields, function(f) {
				a[f] = {
					worsts: _.chunk(_.orderBy(_.map(realCurrent, f)), 3)[0],
					bests: _.chunk(_.reverse(_.orderBy(_.map(realCurrent, f))), 3)[0],
				}
			}.bind(this));

			return a;

		},
		finalData: function() {

			var outPutTable = [];
			var outPutTableMeans = [];

			_.each(this.results.output_tracking, function(i) {
				i.persona = _.find(this.results.persone, { id: i.persona_id });

				if('PO' != _.first(_.map([i.persona], 'last_carriera_sportiva.ruolo_ideale[0].sigla')))
					outPutTableMeans.push(i);

				outPutTable.push(i);
			}.bind(this));

			console.log('A1', outPutTableMeans);
			var meanBase = calculateMean(outPutTableMeans, this.isMatch, this.ux.wholeSessione);

			meanBase.persona = { team: true, cognome: 'Media', nome: '' };
			outPutTable.push(meanBase);

			outPutTable = _.orderBy(outPutTable, [ this.ux.orderBy ], [ this.ux.orderDir ]);

			console.log(this.results.matches_prev);

			var otherBlocks = _.map(this.results.matches_prev, function(a) {
				var c = _.cloneDeep(a);
				console.log('A2', a);
				return calculateMeans(a, true, this.ux.wholeSessione);
			}.bind(this));

			if(this.sessione.sessione_tipo_glo_id == 15) {
				var currentMatch = {
					match: this.results.current_match ? this.results.current_match.match : 'Media Partita',
					tracking: meanBase
				};
				otherBlocks.push(currentMatch);

				var mediaTeam = {
					match: 'Media Squadra',
					tracking: this.results.team_average
				};
				otherBlocks.push(mediaTeam);

			} else {
				
				var mediaTeam = {
					match: 'Media Partite Precedenti',
					tracking: this.results.team_average
				};
				otherBlocks.push(mediaTeam);

				var currentMatch = {
					match: 'Media Squadra',
					tracking: meanBase
				};
				otherBlocks.push(currentMatch);
			}

			return { current: outPutTable, startingDataset: otherBlocks };

		}
	},
	methods: {
		playerColor: function(value) {

			if(value > 100) {
				return 'margin-top: 2px; font-size: 10px; box-shadow: inset 0px 0px 0px 2px #12a14b;';
			} else if(value > 66 && value <= 100) {
				return 'margin-top: 2px; font-size: 10px; box-shadow: inset 0px 0px 0px 2px #ffff00;';
			} else if(value > 33 && value <= 66) {
				return 'margin-top: 2px; font-size: 10px; box-shadow: inset 0px 0px 0px 2px #ff930e;';
			} else if(value <= 33) {
				return 'margin-top: 2px; font-size: 10px; box-shadow: inset 0px 0px 0px 2px #fb0102;';
			}

		},
		printSession: function() {
			window.open(this.$store.state.apiEndPoint + '/tracking-tool/pdf-sessione/'+this.sessione.id+'/'+this.sessione.sessione_tipo_glo_id + "/" + Auth.user.id + "/" + Auth.user.api_token + ( this.ux.wholeSessione ? '?whole=1' : '' ), "_blank");		
		},
		printSessionRadar: function() {
			window.open(this.$store.state.apiEndPoint + '/tracking-tool/pdf-sessione-persone/'+this.sessione.id+'/'+this.sessione.sessione_tipo_glo_id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");		
		},
		ratio: function(a, b) {
			if(this.isMatch)
				return _.round(100*a/b);
			else
				return _.round(100*b/a);
		},
		getBgRatio: function (value) {
			if(this.isMatch) {
				if(value <= 100) {
					return '#12a14b';
				} else if(value > 100 && value <= 110) {
					return '#ffff00';
				} else if(value > 110 && value <= 120) {
					return '#ff930e';
				} else if(value > 120) {
					return '#fb0102';
				}
			} else {
				if(value > 100) {
					return '#12a14b';
				} else if(value > 66 && value <= 100) {
					return '#ffff00';
				} else if(value > 33 && value <= 66) {
					return '#ff930e';
				} else if(value <= 33) {
					return '#fb0102';
				}
			}
		},
		changeOrder: function(key) {
			if(this.ux.orderBy === key) {
				if(this.ux.orderDir == 'asc')
					this.ux.orderDir = 'desc';
				else
					this.ux.orderDir = 'asc';
			} else {
				this.ux.orderBy = key;
				this.ux.orderDir = 'asc';
			}
		},
		isBest: function(value, k) {
			if(this.bests[k].bests.indexOf(value) !== -1)
				return true;
		},
		isWorst: function(value, k) {
			if(this.bests[k].worsts.indexOf(value) !== -1)
				return true;
		},
		fetchResults: function() {
			this.ux.waiting = true;
			this.$http.post(this.$store.state.apiEndPoint + '/tools/table-tracking/' + this.sessione.id, { wholeSessione: this.ux.wholeSessione }).then(function(data) {
				this.ux.waiting = false;
				this.results = data.data;
			});
		},
		fetchMaximals: function() {
			// console.log('Wooof');
			// console.log(_.map(this.results.persone, 'id'));
			// console.log('Wooof');
		}
	},
	watch: {
		'ux.wholeSessione': {
			immediate: true,
			handler: function() {
				this.fetchResults();
			}
		},
		// 'ux.collapsed': {
		// 	// immediate: true,
		// 	handler: function() {
		// 		this.fetchResults();
		// 	}
		// },
		'ux.view': {
			immediate: true,
			handler: function() {
				if(this.ux.view == 'radar' && this.results) {
					// We need to ask for the maximals! :D
					this.fetchMaximals();
				}
			}
		}
	},
	data: function() {
		return {
			bests_data: null,
			results: false,
			ux: {
				wholeSessione: false,
				view: 'table',
				orderBy: 'total_distance',
				orderDir: 'desc',
				waiting: false,
				collapsed: true
			}
		}
	}
}

</script>


<style scoped>
.pppp .card-header {
	padding-bottom: 8px;
}
</style>