<template>
	<div>
		<div class="d-flex justify-content-center my-1">
			<div class="d-flex py-2 px-3" style="align-items: center; justify-content: center; border: 1px solid #CCCCCC; border-radius:15%;">
				<div v-if="contractInfo" class="pt-2">
					<div class="text-center mb-1" style="font-size: 1.2rem; line-height: 0.5"><strong>{{ contractInfo.contractExpiration.split("-")[0] }}</strong></div>
					<div class="text-center" style="font-size: 0.8rem">{{ contractInfo.contractExpiration.split("-")[2] }}/{{ contractInfo.contractExpiration.split("-")[1] }}<br/>Contratto</div>
				</div>
			</div>
		</div>
		<div class="text-center" style="font-size: 0.8rem" v-if="contractInfo && contractInfo.agencies && contractInfo.agencies.length > 0"><span class="text-muted" style="text-transform: uppercase">Agente:</span> {{ contractInfo.agencies[0] }}</div>
	</div>
</template>

<script>

export default {
	props: [ 'player' ],
	watch: {
		'player': {
			immediate: true,
			handler: function() {
				if(this.player.persona.wyscout)
					this.fetchContractInfo();
			}
		}
	},
	methods: {
		fetchContractInfo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/ws/player/contractinfo/' + this.player.persona.wyscout.chiave).then(function(data) {
				this.contractInfo = data.data;
			});
		}
	},
	data: function() {
		return {
			contractInfo: null
		}
	}
}

</script>