<template>
	<div>
		<b-card>
			<template slot="header">
				<b-row align-v="center"><b-col><strong>Impact on the game</strong></b-col><b-col cols="2" class="pr-2"><Smart-Tag :readonly="read" :value="mean('gruppo_1')" :disabled="true"></Smart-Tag></b-col></b-row>
			</template>
			<div>
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<!--b-th width="7%"></b-th-->
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Resolving problems created by the opponent</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_1.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_1.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Creating problems to the opponent</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_2.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_2.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Interpretation of game situations</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_3.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_3.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">
				<b-row align-v="center"><b-col><strong>Physical Profile</strong></b-col><b-col cols="2" class="pr-2"><Smart-Tag :readonly="read" :value="mean('gruppo_2')" :disabled="true"></Smart-Tag></b-col></b-row>
			</template>
			<div>	
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<!--b-th width="7%"></b-th-->
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Pace / Turn of pace / Acceleration</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_1.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_1.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Activation / Reactions</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_2.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_2.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Power</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_3.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_3.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to impose himself in contact duels</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_4.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_4.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Capacity to repeat high intensity runs / Generosity</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_5.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_5.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>In the air</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_6.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_6.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Coordination / Balance / Agility</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_7.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_2.valore_7.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">
				<b-row align-v="center"><b-col><strong>Technical Profile</strong></b-col><b-col cols="2" class="pr-2"><Smart-Tag :readonly="read" :value="mean('gruppo_3')" :disabled="true"></Smart-Tag></b-col></b-row>
			</template>
			<div>	
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<!--b-th width="7%"></b-th-->
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Receiving skills / Quality of the 1st touch</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_1.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_1.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Ball manipulation</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_2.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_2.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Passing range (short-medium-long)</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_3.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_3.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Attacking end product</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_4.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_4.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Defensive clearances</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_5.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_5.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Use of weaker foot</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_6.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_6.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Set pieces deleveries</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_7.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_3.valore_7.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">
				<b-row align-v="center"><b-col><strong>Psychological Profile</strong></b-col><b-col cols="2" class="pr-2"><Smart-Tag :readonly="read" :value="mean('gruppo_4')" :disabled="true"></Smart-Tag></b-col></b-row>
			</template>
			<div>
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="40%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<!--b-th width="7%"></b-th-->
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Interactions with the different actors of the game</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_1.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_1.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Body language</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_2.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_2.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Reaction after key moments</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_3.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_3.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
						<b-tr>
							<b-td>Presence / Aura</b-td>
							<b-td><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_4.note" /></b-td>
							<b-td><Smart-Tag :readonly="read" v-model="evaluation.evaluation.gruppo_4.valore_4.value"></Smart-Tag></b-td>
							<!--b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td-->
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header"><strong>Conclusion</strong></template>
			<div>
				<b-row>
					<b-col><b-form-textarea rows="2" max-rows="8" size="sm" :readonly="read" v-model="evaluation.evaluation.gruppo_0.valore_0.note"></b-form-textarea></b-col>
					<b-col cols="2"><Smart-Tag :readonly="read" :value="evaluation.evaluation.gruppo_0.valore_0.value" :disabled="true"></Smart-Tag></b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>

export default {
	props: [ 'evaluation', 'read' ],
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
	},
	created: function() {

		if(this.evaluation.evaluation == null) {
			this.$set(this.evaluation, 'evaluation', {
				gruppo_0: {
					valore_0: { value: null, note: null },
				},
				gruppo_1: {
					valore_0: { value: null, note: null },
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null }
				},
				gruppo_2: {
					valore_0: { value: null, note: null },
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null },
					valore_4: { value: null, note: null },
					valore_5: { value: null, note: null },
					valore_6: { value: null, note: null },
					valore_7: { value: null, note: null }
				},
				gruppo_3: {
					valore_0: { value: null, note: null },
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null },
					valore_4: { value: null, note: null },
					valore_5: { value: null, note: null },
					valore_6: { value: null, note: null },
					valore_7: { value: null, note: null }
				},
				gruppo_4: {
					valore_0: { value: null, note: null },
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null },
					valore_4: { value: null, note: null }
				}
			});
		}
	},
	watch: {
		'evaluation.evaluation': {
			deep: true,
			handler: function() {
				var media = null;
				var count = 0;
				if(this.evaluation.evaluation) {
					_.each(_.keys(this.evaluation.evaluation), function(k) {
						if(k != 'gruppo_0') {
							var value = this.evaluation.evaluation[k].valore_0.value;
							if(value !== null) {
								media += parseFloat(value);
								count++;
							}	
						}
					}.bind(this));
					if(count > 0) {
						media = Math.round(media/count * 100) / 100;
						this.evaluation.evaluation.gruppo_0.valore_0.value = media;
					} else {
						this.evaluation.evaluation.gruppo_0.valore_0.value = null;
					}
				}
			}
		} 
	},
	methods: {
		mean: function(group) {
			var media = null;
			var count = 0;
			if(this.evaluation.evaluation) {
				_.each(_.keys(this.evaluation.evaluation[group]), function(k) {
					if(k != 'valore_0') {
						var value = this.evaluation.evaluation[group][k].value;
						if(value !== null) {
							media += value;
							count++;
						}
					}
				}.bind(this));
				if(count > 0) {
					media = Math.round(media/count * 100) / 100;
					this.evaluation.evaluation[group].valore_0.value = media;
				} else {
					this.evaluation.evaluation[group].valore_0.value = null;
				}
				return this.evaluation.evaluation[group].valore_0.value;
			}
		},
	},
	data: function() {
		return {
		}
	}
}

</script>