<template>
	<div>
		<div>
			<div ref="page_2">
				<b-row class="teamsSchemes mt-2">
					<b-col class="text-center" v-if="Object.keys(formation_home)[0]">
						<span class="scheme">{{ Object.keys(formation_home)[0] }}</span>
					</b-col>
					<b-col class="text-center">
						<slot></slot>
					</b-col>
					<b-col class="text-center" v-if="Object.keys(formation_away)[0]">
						<span class="scheme">{{ Object.keys(formation_away)[0] }}</span>
					</b-col>
				</b-row>
				<div class="teamSheetPitch" style="background-image: url(assets/wyscout/ws.png)">


					<ul class="gears-abs gears-fit dotsTeam teamA" v-if="Object.keys(formation_home)[0]">
						<li v-for="(item, id) in formation_home_players" v-if="getPlayerHome(Object.keys(item)[0])" :key="id" :class=" 'teamA_' + item[Object.keys(item)[0]]['position'] + '' + (getPlayerHome(Object.keys(item)[0]).playerOut > 0 ? ' substituted' : '') ">
							{{ getPlayerHome(Object.keys(item)[0]).shirtNumber }}
							<span class="player-name">{{ getPlayerHome(Object.keys(item)[0]).shortName }}</span>
							<span v-if="getPlayerHome(Object.keys(item)[0]).yellowCards > 0" class="player-amm"></span>
							<span v-if="getPlayerHome(Object.keys(item)[0]).redCards > 0" class="player-esp"></span>
							<span v-if="getPlayerHome(Object.keys(item)[0]).goals > 0" class="player-goal" ><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="getPlayerHome(Object.keys(item)[0]).imageDataURL ? getPlayerHome(Object.keys(item)[0]).imageDataURL : '/assets/varie/profile.jpg'" />
									</div>
									<div class="player_info">
										<h1>{{ getPlayerHome(Object.keys(item)[0]).shortName }}</h1>
										<h2>
											<span v-if="getPlayerHome(Object.keys(item)[0]).playerOut > 0"><img src="/assets/wyscout/out.png" />{{ getPlayerHome(Object.keys(item)[0]).playerOut }}'</span>
											<span v-if="getPlayerHome(Object.keys(item)[0]).yellowCards > 0"><img src="/assets/wyscout/yellow.png" />{{ getPlayerHome(Object.keys(item)[0]).yellowCards }}'</span>
											<span v-if="getPlayerHome(Object.keys(item)[0]).redCards > 0"><img src="/assets/wyscout/red.png" />{{ getPlayerHome(Object.keys(item)[0]).redCards }}'</span>
											<span v-if="getPlayerHome(Object.keys(item)[0]).goals > 0"><img src="/assets/wyscout/ball.png" v-for="n in parseInt(getPlayerHome(Object.keys(item)[0]).goals)" :key="n"/></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>
					<ul class="gears-abs gears-fit dotsTeam teamB" v-if="Object.keys(formation_away)[0]">
						<li v-for="(item, id) in formation_away_players" :key="id" v-if="getPlayerAway(Object.keys(item)[0])" :class="'teamB_' + item[Object.keys(item)[0]]['position'] + '' + (getPlayerAway(Object.keys(item)[0]).playerOut > 0 ? ' substituted': '')">
							{{ getPlayerAway(Object.keys(item)[0]).shirtNumber }}
							<span class="player-name">{{ getPlayerAway(Object.keys(item)[0]).shortName }}</span>
							<span v-if="getPlayerAway(Object.keys(item)[0]).yellowCards > 0" class="player-amm"></span>
							<span v-if="getPlayerAway(Object.keys(item)[0]).redCards > 0" class="player-esp"></span>
							<span v-if="getPlayerAway(Object.keys(item)[0]).goals > 0" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png" /></span>

							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="getPlayerAway(Object.keys(item)[0]).imageDataURL ? getPlayerAway(Object.keys(item)[0]).imageDataURL : '/assets/varie/profile.jpg'"/>
									</div>
									<div class="player_info">
										<h1>{{ getPlayerAway(Object.keys(item)[0]).shortName }}</h1>
										<h2>
											<span v-if="getPlayerAway(Object.keys(item)[0]).playerOut > 0"><img src="/assets/wyscout/out.png" />{{ getPlayerAway(Object.keys(item)[0]).playerOut }}'</span>
											<span v-if="getPlayerAway(Object.keys(item)[0]).yellowCards > 0"><img src="/assets/wyscout/yellow.png" />{{ getPlayerAway(Object.keys(item)[0]).yellowCards }}'</span>
											<span v-if="getPlayerAway(Object.keys(item)[0]).redCards > 0"><img src="/assets/wyscout/red.png" />{{ getPlayerAway(Object.keys(item)[0]).redCards }}'</span>
											<span v-if="getPlayerAway(Object.keys(item)[0]).goals > 0"><img src="/assets/wyscout/ball.png" v-for="n in parseInt(getPlayerAway(Object.keys(item)[0]).goals)" :key="n" /></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>

				<div class="teamSubstitutions clearfix">

					<ul class="dotsTeam teamA">
						<li v-for="(player, index) in getSubstitutionsHome" :key="'subA_' + index">
							{{ player.shirtNumber }}
							<span v-if="player.yellowCards > 0" class="player-amm"></span>
							<span v-if="player.redCards > 0" class="player-esp"></span>
							<span v-if="player.goals > 0" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="player.imageDataURL ? player.imageDataURL : '/assets/varie/profile.jpg'" />
									</div>
									<div class="player_info">
										<h1>{{ player.shortName }}</h1>
										<h2>
											<span v-if="player.playerIn > 0"><img src="/assets/wyscout/in.png" />{{ player.playerIn }}'</span>
											<span v-if="player.yellowCards > 0"><img src="/assets/wyscout/yellow.png" />{{ player.yellowCards }}'</span>
											<span v-if="player.redCards > 0"><img src="/assets/wyscout/red.png" />{{ player.redCards }}'</span>
											<span v-if="player.goals > 0"><img src="/assets/wyscout/ball.png" v-for="n in parseInt(player.goals)" :key="n"/></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>

					<ul class="dotsTeam teamB">
						<li v-for="(player, index) in getSubstitutionsAway" :key="'subB_' + index">
							{{ player.shirtNumber }}
							<span v-if="player.yellowCards > 0" class="player-amm"></span>
							<span v-if="player.redCards > 0" class="player-esp"></span>
							<span v-if="player.goals > 0" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>
							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="player.imageDataURL ? player.imageDataURL : '/assets/varie/profile.jpg'" />
									</div>
									<div class="player_info">
										<h1>{{ player.shortName }}</h1>
										<h2>
											<span v-if="player.playerIn > 0"><img src="/assets/wyscout/in.png" />{{ player.playerIn }}'</span>
											<span v-if="player.yellowCards > 0"><img src="/assets/wyscout/yellow.png" />{{ player.yellowCards }}'</span>
											<span v-if="player.redCards > 0"><img src="/assets/wyscout/red.png" />{{ player.redCards }}'</span>
											<span v-if="player.goals > 0"><img src="/assets/wyscout/ball.png" v-for="n in parseInt(player.goals)" :key="n" /></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			modalita: null,
			window: {
				width: 0,
				height: 0
			},
			ruoli_sigle: ['PO', 'DC', 'DCS', 'DCD', 'TS', 'TD', 'CC', 'CID', 'CIS', 'ES', 'ED', 'TQC', 'AC', 'AS', 'AD', 'PC']
		}
	},
	props: [ 'formazioni', 'home', 'away', 'homeId', 'awayId' ],
	mounted: function() {
		this.window.width = window.innerWidth;
		this.window.height = window.innerHeight;
	},
	computed: {
		formation_home: function() {
			if(!this.homeId) return [];
			if(!this.formazioni) return [];
			if (this.formazioni[this.homeId]) {
				return this.formazioni[this.homeId]["1H"][ Object.keys(this.formazioni[this.homeId]["1H"])[0] ];
			}
			return [];
		},
		formation_away: function() {
			if(!this.awayId) return [];
			if(!this.formazioni) return [];
			if (this.formazioni[this.awayId]) {
				return this.formazioni[this.awayId]["1H"][
				Object.keys(this.formazioni[this.awayId]["1H"])[0]
				];
			}
			return [];
		},
		formation_home_players: function() {
			if(Object.keys(this.formation_home)[0]) {
				return this.formation_home[Object.keys(this.formation_home)[0]].players;
			}
			return [];
		},
		formation_away_players: function() {
			if(Object.keys(this.formation_away)[0]) {
				return this.formation_away[Object.keys(this.formation_away)[0]].players;
			}
			return [];
		},
		getSubstitutionsHome() {
			return this.home.filter(player => player.playerIn > 0);
		},
		getSubstitutionsAway() {
			return this.away.filter(player => player.playerIn > 0);
		},
	},
	methods: {
		getPlayerHome: function(wyId) {
			return this.home.find(function(player) { return player.wyId == wyId });
		},
		getPlayerAway: function(wyId) {
			return this.away.find(function(player) { return player.wyId == wyId });
		},
	}
}

</script>