<template>
	<div>
		<headapp></headapp>
		<b-card class="tab-content">
			<search />
		</b-card>
		<footerapp></footerapp>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			ux: {
				search: '',
				loading: true,
			}
		}
	},
	components: {
		search: require('@/components/layout/scout/search.vue').default,
	},
	created: function() {
	},
	methods: {
	},
	computed: {
	},
}

</script>