<template>
	<div>
		<b-overlay :show="loading">
			<Intestazione :match="partita" :readonly="true" v-if="partita && partitaWs" :label="partita.partita.label" :date="dataPartita" :competizione="partita.competizione.paese.paese + ' - ' + partita.competizione.nome">
				<slot slot="right">
					<div v-if="osservatori && osservatori.length > 0" class="mr-2 text-right">
						{{ osservatori[page - 1].nomeCompleto }}<br/>{{ valutazioniSelezionate[0].created_at | date }}
					</div>
				</slot>
			</Intestazione>
			<b-row v-if="valutazioniPartita">
				<b-col>
					<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
						{{ homeLabel }}
					</b-card>
					<ListaValutazioni :valutazioni="valutazioniSelezionate" v-if="partitaWs" :formazione="partitaWs.home"></ListaValutazioni>
				</b-col>
				<b-col>
					<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
						{{ awayLabel }}
					</b-card>
					<ListaValutazioni :valutazioni="valutazioniSelezionate" v-if="partitaWs" :formazione="partitaWs.away"></ListaValutazioni>
				</b-col>
			</b-row>
			<b-card no-body class="p-2 mb-1"> 
				<Campetto v-if="partitaWs" :homeId="partitaWs.homeId" :awayId="partitaWs.awayId" :home="partitaWs.home" :away="partitaWs.away" :formazioni="partitaFormationsWs">		
				</Campetto>	
			</b-card>
			<div class="mt-2" v-if="pages > 1">
				<b-pagination size="sm" v-model="page" :total-rows="pages" :per-page="1" align="center"></b-pagination>
			</div>
		</b-overlay>
	</div>
</template>

<script>

import moment from 'moment';
export default {
	props: [ 'partitaId', 'match', 'matchWs', 'matchFormationsWs' ],
	data: function() {
		return {
			loadingMatch: false,
			loadingMatchWs: false,
			loadingFormation: false,
			loadingVal: false,

			partita: null,
			partitaWs: null,
			partitaFormationsWs: null,
			
			valutazioniPartita: null,
			page: 1,
		}
	},
	components: {
		Campetto: require('@/components/layout/scout/campetto.vue').default,
		Intestazione: require('@/components/layout/scout/intestazione-partita.vue').default,
		ListaValutazioni: require('@/components/layout/scout/lista-valutazioni.vue').default,
	},

	created() {
		this.fetchMatch();
		this.fetchValutazioni();
	},
	methods: {
		fetchMatch: function() {
			if(this.match) {
				this.partita = this.match;
				if(this.partita.partita.wyId) {
					this.fetchMatchWithPlayerWS();
					this.fetchMatchFormationsWS();
				}
			} else {
				this.loadingMatch = true;
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/partita/' +  this.partitaId).then(function(data) {
					this.partita = data.data;
					this.loadingMatch = false;
					if(this.partita.partita.wyId) {
						this.fetchMatchWithPlayerWS();
						this.fetchMatchFormationsWS();
					}
				});
			}
		},
		fetchValutazioni: function() {
			this.loadingVal = true;
			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/has', { address: {Partite: this.partitaId }} ).then(function(data) {
				this.valutazioniPartita = data.data;
				this.loadingVal = false;
			});
		},
		fetchMatchWithPlayerWS: function() {
			if(this.matchWs) {
				this.partitaWs = this.matchWs;
			} else {
				this.loadingMatchWs = true;
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/match/with/players/' +  this.partita.partita.wyId, {params: {no_val_breve: true }}).then(function(data) {
					this.partitaWs = data.data;
					this.loadingMatchWs = false;
				});
			}
		},
		fetchMatchFormationsWS: function() {
			if(this.matchFormationsWs) {
				this.partitaFormationsWs = this.matchFormationsWs;
			} else {
				this.loadingFormation = true;
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/match/formations/' +  this.partita.partita.wyId).then(function(data) {
					this.partitaFormationsWs = data.data;
					this.loadingFormation = false;
				});
			}
		},
	},
	computed: {
		dataPartita: function() {
			return moment(this.partita.partita.date, 'LLL');
		},
		loading: function() {
			return this.loadingVal || this.loadingMatch || this.loadingMatchWs || this.loadingFormation;
		},
		osservatori: function() {
			return _.uniqBy(_.map(this.valutazioniPartita, 'persona'), 'id');
		},
		valutazioniByUtente: function() {
			return _.groupBy(this.valutazioniPartita, 'persona_utente_id');
		},
		valutazioniSelezionate: function() {
			return this.osservatori.length ? this.valutazioniByUtente[this.osservatori[this.page - 1].id] : [];
		},
		pages: function() {
			return _.size(this.osservatori);
		},
		awayLabel: function() {
			return this.partita && this.partita.partita ? this.partita.partita.label.split(",")[0].split("-")[1] : '';
		},
		homeLabel: function() {
			return this.partita && this.partita.partita ? this.partita.partita.label.split(",")[0].split("-")[0] : '';
		},
	},
	filters: {
		date: function(i) {
			return moment(i).format('DD/MM/YYYY')
		}
	},
}

</script>