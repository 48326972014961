<template>

	<div>

		<headapp></headapp>

		<b-card class="tab-content">
			<div style="background-color: white" class="breadcrumbWrap">
				<div style="background-color: rgba(0,0,0,.06); border-radius: 3px; width: auto" class="px-4 py-2 mb-1">
					<b-row align-v="center">
						<b-col cols="9">
							<b-breadcrumb style="background-color: transparent" class="m-0 p-0" :items="breadcrumb"></b-breadcrumb>
						</b-col>
						<b-col class="text-right">
							<b-form-input size="sm" v-model="ux.search" placeholder="Quick search"></b-form-input>
						</b-col>
					</b-row>
				</div>

			</div>

			<b-card>
				<b-overlay :show="ux.loading">
					<b-row>
						<b-col sm="1" v-for="p in filtered_paesi" style="padding: 0.2rem">
							<router-link :to="{ name: 'scout_competizioni', params: { continente_id: $route.params.continente_id, paese_id: p.id } }">
								<b-card class="small text-center" style="cursor: pointer;height:100%">
									<flag :country="p.sigla" :size="64"></flag><br/>
									{{p.paese}}
								</b-card>
							</router-link>
						</b-col>
					</b-row>

				</b-overlay>
			</b-card>
		</b-card>

		<footerapp></footerapp>

	</div>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			paesi: null,
			ux: {
				search: '',
				loading: true,
			},
		}
	},
	components: {
		flag: require('@/components/layout/flag.vue').default,
	},
	created: function() {
		this.fetch();
	},
	methods: {
		fetch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/continente/' + this.$route.params.continente_id).then(function(data) {
				this.paesi = data.data;
				this.ux.loading = false;
			});
		},
	},
	computed: {
		continente: function() {
			if(this.paesi && this.paesi.length > 0) {
				return this.paesi[0];
			}
			return null;
		},

		breadcrumb: function() {

			var b = [];

			b.push({
				text: 'Continenti',
				to: {
					name: 'scout_continenti'
				}
			});

			b.push({
				text: this.continente ? this.continente.paese : '',
				to: {
					name: 'scout_paesi',
					params: {
						continente_id: this.$route.params.continente_id
					}
				}
			});

			return b;

		},
		filtered_paesi: function () {
			if (this.ux.search != "") {
				return this.paesi.filter((paese) =>
					paese.paese.toLowerCase().includes(this.ux.search.toLowerCase()));
			}
			return this.paesi;
		},
	},
}

</script>